export default {
  auth: {
    resetPasswordLink: {
      forgotPassword: "Mot de passe oublié?",
    },
  },
  cards: {
    needLoginCard: {
      needLogged: "Vous devez être connecté pour voir ce contenu.",
    },
    productHomeCard: {
      inceptionToDateReturn: "Rendement depuis le début de l'exercice",
      lastDay: "Changement du dernier jour",
      lastPrice: "Dernier prix",
      na: "N/A",
      viewDetails: "Voir les détails",
      yearToDateReturn: "Rendement depuis le début de l'année",
    },
  },
  compliance: {
    cookies: {
      buttonText: "Accepter",
      cookiesUsage:
        "Ce site Web utilise des cookies pour améliorer sa convivialité. En utilisant ce site Web, vous déclarez votre consentement à l'utilisation de cookies, ainsi qu'à la politique de confidentialité où vous trouverez de plus amples informations sur les cookies. Lien: {0}.",
      privacyPolicy: "Politique de confidentialité",
    },
  },
  components: {
    downloadAsPngTooltip: "Télécharger au format PNG",
    details: {
      factsheet: {
        aumFormat: (ctx) =>
          `${ctx.named("currency")} ${(ctx.named("aum") / 1000000).toFixed(
            1
          )} million${ctx.named("aum") > 1000000 ? "s" : ""}${
            ctx.named("asOfDate") ? ` (au ${ctx.named("asOfDate")})` : ""
          }`,
        lastPrice: (ctx) => `${ctx.named("currency")} ${ctx.named("lastPrice").toFixed(2)}`,
      },
      overview: {
        aumFormat: (ctx) =>
          `${ctx.named("currency")} ${(ctx.named("aum") / 1000000).toFixed(1)} million${ctx.named("aum") > 1000000 ? "s" : ""} (au ${ctx.named("asOfDate")})`,
        lastPrice: (ctx) =>
          `${ctx.named("currency")} ${ctx.named("lastPrice").toFixed(2)} (au ${ctx.named("asOfDate")})`,
      },
    },
  },
  datePickers: {
    datePicker: {
      selectDateTooltip: "Choisir une date",
    },
    dateRangePicker: {
      from: "De (aaaa-mm-jj)",
      fullPeriod: "Période complète",
      to: "À (aaaa-mm-jj)",
    },
    zoomPicker: {
      fiveYears: "5A",
      fullPeriod: "MAX",
      oneMonth: "1M",
      oneYear: "1A",
      sixMonths: "6M",
      threeMonths: "3M",
      tenYears: "10Y",
      threeYears: "3A",
      twoYears: "2A",
      ytd: "YTD",
    },
  },
  dropdowns: {
    iconsDropdown: {
      noIcon: "Pas d'icône",
    },
  },
  editPrompt: {
    copyInAllLangs: "Copier dans toutes les langues",
    editField: "Modifier le champ",
    enterText: "Entrer un texte",
    overwriteDataAllShareClasses: "Écraser les données de toutes les classes d'actions",
    id: "ID",
    idTooltip:
      "Cet identifiant unique sert de référence pour le composant au sein de la plateforme. Chaque composant que vous créez doit avoir un identifiant distinct. Nous recommandons la convention suivante pour une meilleure lisibilité : utiliser le titre du composant, le convertir en minuscules, remplacer les espaces par des tirets. Pour un composant intitulé 'Mon tableau analytique', l'identifiant serait 'mon-tableau-analytique'.",
    needUniqueId:
      "L'identifiant fourni est actuellement utilisé par un autre composant dans l'emplacement actuel ou dans un emplacement lié. Veuillez fournir un identifiant distinctif pour garantir l'unicité.",
    numberOfDecimals: "Nombre de décimales",
    numberOfDecimalsTooltip:
      "Le nombre de décimales pour tous les nombres affichés dans le composant. Notez que ça n'inclut pas les axes des graphiques.",
    withBenchmark: "Montrer avec la benchmark",
    footnote: "Texte sous le composant",
    placeholderFootnote:
      "Un texte qui apparaît en dessous du composant. Par exemple, 'Les frais sont inclus'",
    langTabError: "Certains champs ne sont pas valides dans les langues suivantes:",
    settingsOnlyUsedOnOverviewTooltip: "Ce paramètre ne concerne que la page 'Aperçu'.",
    showOnFactsheet: "Afficher sur la factsheet",
    tabError: "Certains champs ne sont pas valides",
    title: "Titre",
    titleEdit: (ctx) => `Éditer ${ctx.named("componentId")}`,
    titleAdd: "Ajouter un composant",
    unsavedChangesAlert:
      "Vous avez des modifications non enregistrées. Si vous quittez sans sauvegarder, elles seront perdues.",
    factsheetFooter: {
      saveSuccess: "Le pied de page a été mis à jour avec succès",
      title: "Pied de page",
      text: "Texte",
      showPageNumber: "Afficher le numéro de page",
      showFooterLine: "Afficher la ligne de bas de page",
    },
    factsheetHeader: {
      backgroundImage: "Image de fond",
      hideProductName: "Cacher le nom du produit",
      logo: "Logo",
      onlyOnFirstPage: "Seulement sur la première page",
      saveSuccess: "L'en-tête a été mis à jour avec succès",
      textInPrimary: "Texte en couleur de la marque",
      title: "En-tête",
      useCompanyLogo: "Utiliser le logo de l'entreprise",
      datePosition: {
        bottomLeft: "En bas à gauche",
        bottomRight: "En bas à droite",
        standard: "Standard",
        title: "Position de la date",
        topLeft: "En haut à gauche",
        topRight: "En haut à droite",
      },
    },
    factsheetStyle: {
      primaryColor: "Couleur principale",
      saveSuccess: "Le style a été mis à jour avec succès",
      title: "Style",
      fontSize: {
        normal: "Normale",
        large: "Grande",
        small: "Petite",
        title: "Taille de la police",
      },
      spacing: {
        comfortable: "Confortable",
        compact: "Compact",
        title: "Espacement",
      },
      titleBorder: {
        above: "Au-dessus du titre",
        below: "En-dessous du titre",
        none: "Pas de soulignement",
        title: "Soulignement du titre",
      },
    },
    genericComponent: {
      addColumn: "Ajouter une colonne",
      addRow: "Ajouter une ligne",
      addData: "Ajouter une donnée",
      barChart: "Diagramme à barres",
      cantChangeType:
        "Vous ne pouvez pas modifier le type d'un composant générique une fois que vous l'avez enregistré. Si vous souhaitez un type différent, vous devez créer un nouveau composant générique.",
      columnWithIndex: (ctx) => `Colonne ${ctx.named("idx")}`,
      data: "Donnée",
      date: "Date",
      displayValuesAsPerc: "Affiche les valeurs en pourcentage",
      displayValuesAsPercTooltip: "Affiche les valeurs avec un symbole '%' dans le graphique",
      donutChart: "Graphique en donut",
      exampleSector: "Ex., Secteurs",
      header: "En-tête",
      key: "Légende",
      image: "Image",
      lineChart: "Graphique linéaire",
      needData:
        "Vous ne pouvez pas sauvegarder un composant générique sans aucune donnée ou partiellement rempli. Supprimez-le, ajoutez une donnée ou complétez les données manquantes avant de sauvegarder.",
      pieChart: "Graphique circulaire",
      removeData: "Supprimer la donnée",
      showColumnHeader: "Afficher l'en-tête de la colonne",
      table: "Tableau",
      text: "Texte",
      type: "Type",
      useBrandColor: "Utiliser la couleur de la marque",
      value: "Valeur",
      video: "Vidéo",
      weightPercent: "Poids (en %)",
    },
    contacts: {
      addContact: "Ajouter un contact",
      additionalText: "Texte supplémentaire",
      email: "E-mail",
      exampleEmail: "Ex., jdoe@mail.com",
      exampleName: "Ex., John Doe",
      examplePhone: "Ex., +41 79 123 45 67",
      exampleRole: "Ex., Partenaire",
      imageSquare: "Image (carré)",
      name: "Nom",
      phone: "Téléphone",
      removeContact: "Supprimer le contact",
      role: "Rôle",
    },
    dialog: {
      copyInAllLangs: {
        title: "Copier dans toutes les langues",
        text: "Vous allez copier toutes les valeurs de la langue actuelle dans toutes les autres langues. En êtes-vous sûr(e)?",
      },
      saveFieldInAllShareClasses: {
        title: "Écraser",
        text: "Vous êtes sur le point d'écraser ce champ dans toutes les autres classes d'actions de ce produit, pas seulement la classe d'actions actuelle. Etes-vous sûr(e)?",
      },
      saveInAllShareClasses: {
        title: "Écraser",
        text: (ctx) =>
          `Vous êtes sur le point d'écraser les données "${ctx.named(
            "tabName"
          )}" de toutes les autres classes d'actions de ce produit avec les données "${ctx.named(
            "tabName"
          )}" de cette classe d'actions (pas seulement les derniers changements que vous avez effectués). Etes-vous sûr(e)?`,
      },
    },
    description: {
      contentTitle: "Description",
    },
    disclaimer: {
      contentTitle: "Clause de non-responsabilité",
      defaultWarning:
        "Vous n'avez actuellement pas de clause de non-responsabilité pour ce produit. C'est pourquoi la clause de non-responsabilité standard a été utilisée. Si vous rédigez une clause de non-responsabilité ici, elle s'affichera uniquement pour ce produit.",
    },
    historicalPricesChart: {
      adjusted: "Utiliser les prix ajustés",
      adjustedTooltip:
        "En général, les prix ajustés désignent les prix qui sont ajustés pour tenir compte des dividendes, des splits et d'autres événements. Les prix non ajustés sont simplement le coût d'une action du produit à la fin de la journée.",
      defaultLogScale: "Par défaut dans l'échelle logarithmique",
      showLogScaleSwitch: "Afficher le switch d'échelle logarithmique",
      priceIndexedTo100: "Utiliser les prix indexés à 100",
    },
    holdingOverTime: {
      elementsInOthers: {
        title: 'Éléments à considérer comme "Autres"',
        multiSelect: {
          placeholder: "Sélectionnez des éléments",
          selectLabel: "Appuyez sur la touche Entrée pour sélectionner",
          deselectLabel: "Appuyez sur la touche Entrée pour supprimer",
          selectedLabel: "Sélectionné",
          limitText: (ctx) => `et ${ctx.named("count")} de plus`,
        },
      },
    },
    keyCharacteristics: {
      addKeyCharacteristics: "Ajouter une caractéristique clé",
      exampleDynamicExposure: "Ex., Exposition dynamique",
      exampleWeDynamicallyAdapt: "Ex., Nous adaptons dynamiquement ...",
      header: "En-tête",
      icon: "Icône",
      paragraph: "Paragraphe",
      removeKeyCharacteristics: "Supprimer la caractéristique clé",
    },
    monthlyReturnsChart: {
      startDate: "Date de début",
      startDateTooltip:
        "Sélectionnez une date de début (au format AAAA-MM-JJ) pour le graphique. Le graphique s'ajustera automatiquement pour afficher les données à partir de la première date disponible pour le produit ou de la date que vous avez choisie, selon celle qui est la plus tardive. Si vous laissez ce champ vide, toutes les données de prix disponibles pour le produit seront affichées.",
    },
    monthlyReturnsTable: {
      descending: "Trier les années par ordre décroissant",
      startDate: "Date de début",
      startDateTooltip:
        "Sélectionnez une date de début (au format AAAA-MM-JJ) pour la table des rendements. La table s'ajustera automatiquement pour afficher les données à partir de la première date disponible pour le produit ou de la date que vous avez choisie, selon celle qui est la plus tardive. Si vous laissez ce champ vide, toutes les données de prix disponibles pour le produit seront affichées.",
      benchmark: "Benchmark",
      product: "Produit",
      return: "Rendement",
      nameReplacements: "Remplacer les noms par défaut",
      nameReplacementsTooltip:
        "Personnalisez le nom du produit et de la benchmark dans le composant. Laissez vide pour conserver les noms existants par défaut.",
    },
    notify: {
      componentSaveSuccess: (ctx) =>
        `Votre composant '${ctx.named("componentId")}' a été mis à jour avec succès.`,
    },
    overviewHeader: {
      backgroundImage: "Image de fond",
      backgroundImageTooltip:
        "Veuillez noter que l'image en cours d'examen est également utilisée comme image de la fiche produit sur la page d'accueil. Par conséquent, toute modification de cette image affectera simultanément la représentation du produit sur la page d'accueil",
      text: "Texte",
      textTooltip:
        "Veuillez noter que le texte actuellement à l'étude est également utilisé comme texte de la fiche produit sur la page d'accueil. Par conséquent, toute modification de ce texte affectera simultanément la représentation du produit sur la page d'accueil.",
      showDownloadLastFactsheetButton:
        'Afficher le bouton "@:components.extras.downloadLastFactsheet.downloadLastFactsheet"',
      showDownloadLastFactsheetButtonTooltip:
        'Pour que le bouton soit visible par un utilisateur, un document doit être marqué comme "@:pages.products.documents.prompt.uploadFile.shouldConsiderLastFactsheet" dans la section "Documents", et il doit être disponible dans la langue sélectionnée par l\'utilisateur.',
      saveSuccess: "L'en-tête a été mis à jour avec succès",
      showFollowButton: "Afficher le bouton pour suivre le produit",
      title: "Éditer l'en-tête de l'aperçu",
    },
    predefinedHoldingComponent: {
      chartType: "Type de graphique",
      barChart: "Diagramme à barres",
      donutChart: "Graphique en donut",
      pieChart: "Graphique circulaire",
      table: "Tableau",
      useBrandColor: "Utiliser la couleur de la marque",
      assetClasses: {
        editAssetClasses: "Éditer les classes d'actifs",
      },
      currencyExposure: {
        editCurrencyExposure: "Éditer le risque d'exposition",
      },
      instrumentTypes: {
        editInstrumentTypes: "Éditer les types d'instruments",
      },
      onlyShowTopThenOthers: {
        title: "Afficher les premiers uniquement",
        tooltip:
          'N\'afficher que les X premiers (nombre choisi), et fusionner les autres dans "Autres". Laisser vide pour tout afficher.',
      },
      regions: {
        editRegions: "Éditer les régions",
      },
      sectors: {
        editSectors: "Éditer les secteurs",
      },
    },
    productDetails: {
      addDetail: "Ajouter un détail",
      exampleIsin: "Ex., ISIN",
      exampleLU: "Ex., LU2220388479",
      key: "Clé",
      noteDragAndDrop: "Remarque : faites glisser les lignes pour réorganiser les détails.",
      removeDetail: "Supprimer le détail",
      showAuM: "Montrer l'AuM",
      showLastPrice: "Montrer le dernier prix",
      value: "Valeur",
    },
    relativePerformanceChart: {
      footnote: "Note de bas de page",
      placeholderFootnote:
        "Un texte qui apparaît sous le graphique. Par exemple, 'Les frais sont inclus'",
    },
    riskProfile: {
      level: "Niveau de risque",
    },
    shareClassesTable: {
      bloomberg: "Bloomberg",
      class: "Classe",
      defaultFootnote:
        "Perf. lanc. : les rendements pour les périodes de plus d'un an depuis la création sont annualisés, et non annualisés s'ils sont inférieurs à un an.",
      footnote: "Note en-dessous de la table",
      inceptionDate: "Date de création",
      isin: "ISIN",
      mgmtFee: "Frais de gestion",
      perfFee: "Frais de perf.",
      title: "Modifier le tableau des classes d'actions",
    },
    statistics: {
      benchmark: "Benchmark",
      product: "Produit",
      nameReplacements: "Remplacer les noms par défaut",
      nameReplacementsTooltip:
        "Personnalisez le nom du produit et de la benchmark dans le composant. Laissez vide pour conserver les noms existants par défaut. Par exemple, utilisez un terme comme 'Produit' pour abréger et économiser de l'espace d'affichage.",
      fieldsToDisplay: "Champs à afficher",
      noteDragAndDrop: "Remarque : faites glisser les lignes pour réorganiser les statistiques.",
      shownStatisticName: "Nom de la statistique affichée",
      showStatistic: "Afficher la statistique",
      statisticId: "Identifiant de la statistique",
    },
    statisticsWithPeriods: {
      benchmark: "Benchmark",
      product: "Produit",
      nameReplacements: "Remplacer les noms par défaut",
      nameReplacementsTooltip:
        "Personnalisez le nom du produit et de la benchmark dans le composant. Laissez vide pour conserver les noms existants par défaut. Par exemple, utilisez un terme comme 'Produit' pour abréger et économiser de l'espace d'affichage.",
      fieldsToDisplay: "Champs à afficher",
      noteDragAndDrop: "Remarque : faites glisser les lignes pour réorganiser les statistiques.",
      periodsTitle: "Périodes à afficher",
      shownStatisticName: "Nom de la statistique affichée",
      showStatistic: "Afficher la statistique",
      statisticId: "Identifiant de la statistique",
      periods: {
        1: "1M",
        3: "3M",
        6: "6M",
        ytd: "YTD",
        12: "1A",
        36: "3A",
        60: "5A",
        max: "MAX",
      },
    },
    topHoldings: {
      fieldsToDisplay: "Champs à afficher",
      showHeader: "Afficher l'en-tête",
      showTotal: "Afficher le total",
      numHoldings: {
        title: "Nombre de positions à afficher",
        tooltip:
          "Si vous laissez ce champ vide, toutes les données disponibles pour les avoirs du produit seront affichées.",
      },
      holdingsToIgnore: {
        title: "Holdings à ignorer",
        holdingsMultiselect: {
          placeholder: "Sélectionnez les holdings à ignorer",
          selectLabel: "Appuyez sur la touche Entrée pour sélectionner",
          deselectLabel: "Appuyez sur la touche Entrée pour supprimer",
          selectedLabel: "Sélectionné",
          limitText: (ctx) => `et ${ctx.named("count")} de plus`,
        },
      },
      customizeTable: "Personnaliser la table",
      addColumn: "Ajouter une colonne",
      columnWithIndex: (ctx) => `Colonne ${ctx.named("idx")}`,
    },
  },
  extras: {
    changeRoles: {
      changeRoles: "Changer les rôles",
      notify: {
        errorText: "Aucun rôle n'a été mis à jour. Raison: ",
        successText: "Les rôles des utilisateurs ont été mis à jour.",
      },
    },
    deleteGenericComponent: {
      delete: "Supprimer",
      deleteAllComponents: "Supprimer tous les composants",
      deleteOnlyThisComponent: "Supprimer uniquement ce composant",
      deleting: "Suppression ...",
      deleteSuccess: "Suppression réussie",
      page: "Page",
      shareClass: "Classe d'actions",
      textIsNotLinked:
        "Vous êtes sur le point de supprimer définitivement un composant générique. Cette action ne peut être annulée. Veuillez confirmer que vous souhaitez procéder à la suppression.",
      textIsLinkedFirstPart:
        "Vous êtes sur le point de supprimer définitivement un composant générique. Cette action ne peut être annulée.  Notez que ce composant est lié au même(s) composant(s) dans ce qui suit: ",
      textIsLinkedSecondPart:
        "Veuillez confirmer que vous souhaitez procéder à la suppression et décider si vous souhaitez supprimer uniquement le composant actuel ou si vous souhaitez supprimer ce composant et tous les composants liés.",
      title: (ctx) => `Suppression permanente de  ${ctx.named("componentId")}`,
      pageList: {
        factsheet: "Factsheet",
        overview: "Aperçu",
      },
    },
    downloadLastFactsheet: {
      downloadLastFactsheet: "Télécharger la dernière factsheet",
      downloadingLastFactsheet: "Téléchargement...",
      needLogin: {
        text: "Pour télécharger ce document, vous devez être authentifié.",
        title: "Vous devez être authentifié",
      },
    },
    manualVerifyEmail: {
      forceVerification: "Forcer la vérification de l'e-mail",
      verified: "E-mail vérifié",
      notify: {
        errorText: "La vérification forcée de l'e-mail a échoué.",
        successText: "L'e-mail de l'utilisateur a été vérifié manuellement.",
      },
    },
    riskProfile: {
      higherRisk: "Risque plus élevé",
      lowerRisk: "Risque plus faible",
      potentiallyHigherReward: "Rémunération potentiellement plus élevée",
      potentiallyLowerReward: "Rémunération potentiellement plus faible",
    },
  },
  factsheet: {
    lastPrice: "Dernier prix",
  },
  graphs: {
    logScale: "Échelle logarithmique",
    notEnoughData:
      "Pas assez de données pour tracer ce graphique, veuillez sélectionner une plage de dates plus longue.",
    needMoreData:
      "Pour calculer cette métrique, une certaine quantité de données au début est nécessaire. Élargissez la période de temps pour avoir accès à cette métrique.",
  },
  holdingData: {
    others: "Autres",
  },
  images: {
    uploadImage: {
      darkening: "Obscurcissement",
      file: "Fichier",
      noFile: "Pas de fichier",
      noImage: "Pas d'image",
      selectFile: "Sélectionnez un fichier",
      selectNewImage: "Sélectionnez une image",
      errorTitleTooLarge: "Image trop grande",
      errorMessageTooLarge:
        "L'image est trop grande. Veuillez sélectionner une autre image dont la taille est inférieure à 30 Mo.",
      warningTitleVeryLarge: "Image très grande",
      warningMessageVeryLarge:
        "L'image est très grande. Elle ralentira le chargement des pages qui l'affichent. Pour de meilleures performances, il est conseillé d'utiliser des images de moins de 10 Mo.",
    },
  },
  linkPrompt: {
    componentLinkedTo: "Ce composant est lié à :",
    currentPage: "Page actuelle",
    currentShareClass: "Classe d'actions actuelle",
    linkThroughPagesTooltip:
      'L\'activation de cette fonctionnalité crée une liaison synchronisée entre les composants de plusieurs pages. Par exemple, lier les pages "Factsheet" et " Aperçu" permet de s\'assurer que toute modification apportée à un composant partagé est mise à jour simultanément sur les deux pages. Important : le contenu du composant lié est dupliqué à partir de la page source que vous êtes en train de modifier. Si vous créez le lien alors que vous modifiez le composant "description" de la page "Factsheet", le composant "description" de la page "Factsheet" est copié dans la page " Aperçu"',
    linkThroughShareClassesTooltip:
      'L\'activation de cette fonction crée une connexion synchronisée entre les composants de plusieurs classes de partage. Par exemple, le fait de lier "Mon produit - Classe A" et "Mon produit - Classe B" garantit que toute modification apportée à un composant partagé est mise à jour simultanément dans les deux classes de partage. Important : le contenu du composant lié est dupliqué à partir de la page source que vous êtes en train de modifier. Si vous créez le lien alors que vous modifiez le composant "description" de la page "Mon produit - Classe A", le composant "description" de "Mon produit - Classe A" est copié dans "Mon produit - Classe B" et remplace donc le composant "description" de "Mon produit - Classe B".',
    linkedWithPages: "En lien avec les pages suivantes",
    linkedWithShareClasses: "En lien avec les classes d'actions suivantes",
    page: "Page",
    shareClass: "Classe d'actions",
    title: (ctx) => `Editer le lien de ${ctx.named("componentId")}`,
    unlink: "Délier",
    unlinking: "Déliement...",
    unsavedChangesAlert:
      "Vous avez des modifications non enregistrées. Si vous quittez sans sauvegarder, elles seront perdues.",
    confirmSaveDialog: {
      computingNewLinks: "Calcul des nouveaux liens...",
      onceLinked:
        "Une fois liés, lorsqu'une modification est appliquée à un composant dans l'un de ces emplacements (y compris l'emplacement actuel), tous les autres composants liés seront mis à jour de la même manière. Veuillez confirmer que vous souhaitez procéder à la sauvegarde.",
      overwrittenExistingComponent: "Écrasement d'un composant avec le même identifiant",
      overwrittenExistingComponentTooltip: (ctx) =>
        `Avertissement : Il existe déjà un composant avec l'ID '${ctx.named(
          "componentId"
        )}' à cet emplacement, il sera donc écrasé par ce composant si vous le liez.`,
      title: "Confirmation de la sauvegarde des nouveaux liens",
      youAreAboutToLink:
        "Vous êtes sur le point de lier le composant actuel aux composants situés aux emplacements suivants :",
    },
    factsheetFooter: {
      title: "Modifier les liens dans le pied de page de la factsheet",
      saveSuccess: "La mise à jour des liens du pied de page a été effectuée avec succès",
    },
    factsheetHeader: {
      title: "Modifier les liens dans l'en-tête de la factsheet",
      saveSuccess: "La mise à jour des liens de l'en-tête a été effectuée avec succès",
    },
    factsheetStyle: {
      title: "Modifier les liens dans le style de la factsheet",
      saveSuccess: "La mise à jour des liens du pied de page a été effectuée avec succès",
    },
    notify: {
      linkSaveSuccess: (ctx) =>
        `La mise à jour de la liaison de '${ctx.named(
          "componentId"
        )}' a été effectuée avec succès. `,
    },
    multiselect: {
      placeholder: "Sélectionnez les pages à lier",
      selectLabel: "Appuyer sur la touche Entrée pour sélectionner",
      deselectLabel: "Appuyer sur la touche Entrée pour supprimer",
      selectedLabel: "Sélectionnée",
    },
    overviewHeader: {
      title: "Modifier le lien de l'en-tête de l'aperçu",
      saveSuccess:
        "La mise à jour de la liason de l'en-tête de l'aperçu a été effectuée avec succès",
    },
    pageList: {
      factsheet: "Factsheet",
      overview: "Aperçu",
      analysis: "Analyse",
    },
  },
  pricesManagement: {
    addData: "Ajouter une donnée",
    autoDataUpdate: "Vos données sont mises à jour automatiquement.",
    date: "Date",
    datePlaceholder: "AAAA-MM-JJ",
    dateValidationError: "Vous ne pouvez pas ajouter une date qui a déjà une valeur.",
    exportCsv: "Exporter en CSV",
    importCsv: "Importer depuis un CSV",
    importCsvFormat:
      "Le fichier d'entrée doit être au format CSV, avec deux colonnes et une ligne d'en-tête. La première colonne doit être composée de dates et la seconde de nombres (le prix à la date correspondante). Veuillez vous assurer que votre fichier CSV est valide afin d'éviter tout problème lors de la lecture et de l'analyse des données.",
    loadingData: "Chargement des données...",
    price: "Prix",
    removeData: "Supprimer une donnée",
    benchmarks: {
      noBenchmark: "Aucun benchmark n'est disponible pour ce produit.",
    },
    dialog: {
      removeData: {
        title: "Supprimer un point de donnée",
        text: "Vous allez supprimer complètement un point de donnée. Êtes-vous sûr?",
      },
    },
    notify: {
      dateUsedMultipleTimeError:
        "Plusieurs prix sont associés à une même date. Vous devez vous assurer qu'un prix unique est attribué à chaque date.",
      numberFormatError:
        'Au moins un des nombres a un format non reconnu. Idéalement, vous ne devriez pas utiliser de séparateur pour les milliers et utiliser un point pour le séparateur décimal, car c\'est le format attendu. Par exemple, "102.12", "1380.25", "18.952", ...',
      saveErrorText: "Erreur lors de la mise à jour des prix, veuillez nous contacter.",
      saveSuccessText: "Les prix ont été mis à jour avec succès.",
    },
    prompt: {
      addPrice: "Ajouter un prix",
      importCsv: "Importer des données depuis un fichier CSV",
    },
  },
  tables: {
    metricsTable: {
      na: "N/A",
      name: "Nom",
      showLess: "Afficher moins",
      showMore: "Afficher plus",
    },
    monthlyReturnsTable: {
      benchmark: "Benchmark",
      return: "Rendement",
    },
    performanceTable: {
      computedFromTo: (ctx) =>
        `Performance calculée du ${ctx.named("firstDate")} au ${ctx.named("lastDate")}.`,
      notAvailableData:
        "Données non disponibles soit parce qu'il n'y a pas assez de jours pour les calculer, soit parce que les données ne sont pas significatives (par exemple, ratio de sharpe négatif), soit parce que l'indice de référence n'est pas toujours disponible pendant la période sélectionnée.",
    },
    topHoldingsTable: {
      holdingFields: {
        assetClass: "Classe d'actif",
        currency: "Devise",
        holdingName: "Nom",
        instrumentType: "Type d'instrument",
        isin: "ISIN",
        region: "Région",
        sector: "Secteur",
        value: "Valeur",
        weight: "Poids",
      },
      totalOfPortfolio: "Total du portefeuille",
    },
  },
  thirdParty: {
    vuePhoneNumberInput: {
      countrySelectorLabel: "Code pays",
      countrySelectorError: "Choisir un pays",
      phoneNumberLabel: "Numéro de téléphone",
      example: "Exemple :",
    },
  },
  videos: {
    uploadVideo: {
      file: "Fichier",
      noFile: "Pas de fichier",
      noVideo: "Pas de vidéo",
      selectFile: "Sélectionnez un fichier",
      selectNewVideo: "Sélectionnez une vidéo",
      errorTitleTooLarge: "Vidéo trop grande",
      errorMessageTooLarge:
        "La vidéo est trop grande. Veuillez sélectionner une autre vidéo dont la taille est inférieure à 30 Mo.",
      warningTitleVeryLarge: "Vidéo très grande",
      warningMessageVeryLarge:
        "La vidéo est très grande. Elle ralentira le chargement des pages qui l'affichent. Pour de meilleures performances, il est conseillé d'utiliser des vidéos de moins de 10 Mo.",
    },
  },
};
