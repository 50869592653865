import _ from "lodash";

import { GenericProductCards } from "@/assets/constants/analysisCards";
import { GLOBAL_EVENTS } from "@/assets/constants/eventsDates";
import { UpdateFrequency } from "@/assets/constants/frequencies";
import {
  STANDARD_PERF_STATS_WITH_BENCHMARK,
  STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
} from "@/assets/constants/perfStats";
import { STANDARD_ZOOM_BUTTONS } from "@/assets/constants/zoomButtons";
import exports from "@/assets/scss/exports.module.scss";

const ORG_COLOR = exports["colors-primary"];

// ******---------- The Singularity Fund ----------*****

const singularityFundUsd = {
  productId: "singularity-fund-usd",
  storeModule: "singularityFundUsd",
  productName: "The Singularity Fund (USD)",
  productShortName: "The Singularity Fund",
  productColor: ORG_COLOR,
  productShareClassRoot: "singularity-fund",
  isDefaultShareClass: true,
  oldDataEndDate: "2020-05-15",
  timelineData: [
    {
      color: "primary",
      icon: "StarIcon",
      title: "Product inception",
      desc: "Alvola was issued as an SPV in collaboration with ISP Group and was made available to all qualified investors in Switzerland",
      time: "02 Jun 2021",
    },
    {
      color: "grey",
      icon: "PlusIcon",
      title: "Long volatility leg adjustment",
      desc: "After intensive research, we have slightly adjusted the exposure management of the long volatility leg to further reduce the maximum drawdown of the strategy",
      time: "15 Dec 2021",
    },
  ],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.outperformance,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
      GenericProductCards.beta,
      GenericProductCards.riskAdjustedPerf,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {},
};

const singularityFundEur = _.cloneDeep(singularityFundUsd);
singularityFundEur.productId = "singularity-fund-eur";
singularityFundEur.storeModule = "singularityFundEur";
singularityFundEur.productName = "The Singularity Fund (EUR)";
singularityFundEur.isDefaultShareClass = false;

// ******---------- Volatility Fund ----------*****

const volatilityFund = {
  productId: "volatility-fund",
  storeModule: "volatilityFund",
  productName: "Volatility Fund",
  productColor: ORG_COLOR,
  allowHoldingDataModification: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {},
};

// ******---------- Real Estate Fund ----------*****

const realEstateFund = {
  productId: "real-estate-fund",
  storeModule: "realEstateFund",
  productName: "Real Estate Fund",
  productColor: ORG_COLOR,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {},
};

// ******---------- Non-Traded Product ----------*****

const nonTradedProduct = {
  productId: "non-traded-product",
  storeModule: "nonTradedProduct",
  productName: "Non-Traded Product",
  productColor: ORG_COLOR,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.other,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {},
};

// ******---------- Demo products ----------*****

// TODO: Remove once not needed anymore.
const opiBspFonds = {
  productId: "opi-bsp-fonds",
  storeModule: "opiBspFonds",
  productName: "OPI Bsp Fonds",
  productColor: ORG_COLOR,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: true,
  availableLanguages: ["en"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {},
};

// TODO: Remove once not needed anymore.
const alpinumCreditFundAUsd = {
  productId: "alpinum-credit-fund-a-usd",
  storeModule: "alpinumCreditFundAUsd",
  productName: "Alpinum Credit",
  productColor: ORG_COLOR,
  availableLanguages: ["en"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.other,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.outperformance,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {},
};

const alpinumEquityFocusFundAUsd = {
  productId: "alpinum-equity-focus-fund-a-usd",
  storeModule: "alpinumEquityFocusFundAUsd",
  productName: "Alpinum Equity Focus",
  productColor: ORG_COLOR,
  availableLanguages: ["en"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.other,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {},
};

const alpinumEquityDynamicFundAUsd = {
  productId: "alpinum-equity-dynamic-fund-a-usd",
  storeModule: "alpinumEquityDynamicFundAUsd",
  productName: "Alpinum Equity Dynamic",
  productColor: ORG_COLOR,
  availableLanguages: ["en"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.other,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {},
};

// ******---------- The TEST FUND ----------*****
const testFund1 = {
  productId: "test-fund-1",
  storeModule: "testFund1",
  productName: "Test Fund 1",
  productColor: ORG_COLOR,
  allowPriceDataModification: true,
  allowHoldingDataModification: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.outperformance,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
      GenericProductCards.beta,
      GenericProductCards.riskAdjustedPerf,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {},
};

const testFund2ShareClass1 = {
  productId: "test-fund-2-share-class-1",
  storeModule: "testFund2ShareClass1",
  productName: "Test Fund 2 Share Class 1",
  productShortName: "Test Fund 2",
  productColor: ORG_COLOR,
  productShareClassRoot: "test-fund-2",
  isDefaultShareClass: true,
  allowPriceDataModification: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.outperformance,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
      GenericProductCards.beta,
      GenericProductCards.riskAdjustedPerf,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {},
};

const testFund2ShareClass2 = _.cloneDeep(testFund2ShareClass1);
testFund2ShareClass2.productId = "test-fund-2-share-class-2";
testFund2ShareClass2.storeModule = "testFund2ShareClass2";
testFund2ShareClass2.productName = "Test Fund 2 Share Class 2";
testFund2ShareClass2.isDefaultShareClass = false;

const testFund3ShareClass1 = {
  productId: "test-fund-3-share-class-1",
  storeModule: "testFund3ShareClass1",
  productName: "Test Fund 3 Share Class 1",
  productShortName: "Test Fund 3",
  productColor: ORG_COLOR,
  productShareClassRoot: "test-fund-3",
  isDefaultShareClass: true,
  allowPriceDataModification: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.outperformance,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
      GenericProductCards.beta,
      GenericProductCards.riskAdjustedPerf,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {},
};

const testFund3ShareClass2 = _.cloneDeep(testFund3ShareClass1);
testFund3ShareClass2.productId = "test-fund-3-share-class-2";
testFund3ShareClass2.storeModule = "testFund3ShareClass2";
testFund3ShareClass2.productName = "Test Fund 3 Share Class 2";
testFund3ShareClass2.isDefaultShareClass = false;

export const DEMO_PRODUCTS_DETAILS = [
  singularityFundUsd,
  singularityFundEur,
  volatilityFund,
  realEstateFund,
  nonTradedProduct,
  opiBspFonds,
  alpinumCreditFundAUsd,
  alpinumEquityFocusFundAUsd,
  alpinumEquityDynamicFundAUsd,
  testFund1,
  testFund2ShareClass1,
  testFund2ShareClass2,
  testFund3ShareClass1,
  testFund3ShareClass2,
];

export const DEMO_PRODUCTS = DEMO_PRODUCTS_DETAILS.map((details) => details.storeModule);
