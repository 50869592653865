import "firebase/compat/firestore";

import firebase from "firebase/compat/app";
import { merge } from "lodash";
import Vue from "vue";
import { firestoreAction } from "vuexfire";

import axios from "@/http/axios";
import { getNested } from "@/store/utils";

const state = {
  details: {},
  benchmarks: [],
  validDates: {},
  performanceData: {},
  performanceWithPeriodData: {},
  cumulativeReturnPeriodsFactsheet: {},
  cumulativeReturns: {},
  benchmarkPrices: {},
  lastCumReturns: {},
  drawdowns: {},
  outperformance: {},
  volatility: {},
  beta: {},
  yearlyCumulativeReturns: {},
  monthlyCumulativeReturns: {},
  returns: {},
  riskAdjusted: {},
  holdingsDetails: {},
  holdings: undefined,
  currencyExposure: undefined,
  currencyExposureOverTime: undefined,
  assetClasses: undefined,
  assetClassesOverTime: undefined,
  instrumentTypes: undefined,
  instrumentTypesOverTime: undefined,
  sectors: undefined,
  sectorsOverTime: undefined,
  regions: undefined,
  regionsOverTime: undefined,
  assetsUnderManagement: undefined,
  cumulativeWeightedReturns: {},
  weightedPerformanceData: {},
  performanceTableData: undefined,
  lastLivePrice: {},
  // Note that productConfig works the other way for error detection,
  // null being the base state and undefined the error state.
  productConfig: null,
  productLangsConfig: null,
};

const getters = {
  isBindingReady: (state) => {
    return (
      state.productConfig != null &&
      state.productLangsConfig != null &&
      state.productLangsConfig.length > 0
    );
  },
  getDetails:
    (state) =>
    ({ mode }) => {
      return getNested(state.details, mode);
    },
  getBenchmarks: (state) => {
    return state.benchmarks;
  },
  getValidDates:
    (state) =>
    ({ mode }) => {
      return getNested(state.validDates, mode);
    },
  getBenchmarksForMode: (state) => (mode) => {
    return state.benchmarks.filter((benchmark) => benchmark.modes.includes(mode));
  },
  getPerformanceStats:
    (state) =>
    ({ mode }) => {
      return getNested(state.performanceData, mode);
    },
  getPerformanceWithPeriodStats:
    (state) =>
    ({ mode }) => {
      return getNested(state.performanceWithPeriodData, mode);
    },
  getCumulativeReturnPeriodsFactsheet:
    (state) =>
    ({ mode }) => {
      return getNested(state.cumulativeReturnPeriodsFactsheet, mode);
    },
  getCumulativeReturns:
    (state) =>
    ({ mode }) => {
      return getNested(state.cumulativeReturns, mode);
    },
  getBenchmarkPrices:
    (state) =>
    ({ benchmarkSymbol }) => {
      return getNested(state.benchmarkPrices, benchmarkSymbol);
    },
  getLastCumReturns:
    (state) =>
    ({ mode, period }) => {
      return getNested(state.lastCumReturns, mode, period);
    },
  getDrawdowns:
    (state) =>
    ({ mode }) => {
      return getNested(state.drawdowns, mode);
    },
  getOutperformanceValues:
    (state) =>
    ({ mode }) => {
      return getNested(state.outperformance, mode);
    },
  getVolatilityValues:
    (state) =>
    ({ mode }) => {
      return getNested(state.volatility, mode);
    },
  getBetaValues:
    (state) =>
    ({ mode }) => {
      return getNested(state.beta, mode);
    },
  getYearlyCumulativeReturns:
    (state) =>
    ({ mode }) => {
      return getNested(state.yearlyCumulativeReturns, mode);
    },
  getMonthlyCumulativeReturns:
    (state) =>
    ({ mode }) => {
      return getNested(state.monthlyCumulativeReturns, mode);
    },
  getReturns:
    (state) =>
    ({ mode }) => {
      return getNested(state.returns, mode);
    },
  getRiskAdjusted:
    (state) =>
    ({ mode }) => {
      return getNested(state.riskAdjusted, mode);
    },
  getHoldingsDetails: (state) => {
    return state.holdingsDetails;
  },
  getHoldings: (state) => {
    return state.holdings;
  },
  getCurrencyExposure: (state) => {
    return state.currencyExposure;
  },
  getCurrencyExposureOverTime: (state) => {
    return state.currencyExposureOverTime;
  },
  getAssetClasses: (state) => {
    return state.assetClasses;
  },
  getAssetClassesOverTime: (state) => {
    return state.assetClassesOverTime;
  },
  getInstrumentTypes: (state) => {
    return state.instrumentTypes;
  },
  getInstrumentTypesOverTime: (state) => {
    return state.instrumentTypesOverTime;
  },
  getSectors: (state) => {
    return state.sectors;
  },
  getSectorsOverTime: (state) => {
    return state.sectorsOverTime;
  },
  getRegions: (state) => {
    return state.regions;
  },
  getRegionsOverTime: (state) => {
    return state.regionsOverTime;
  },
  getAssetsUnderManagement: (state) => {
    return state.assetsUnderManagement;
  },
  getCumulativeWeightedReturns:
    (state) =>
    ({ mode }) => {
      return getNested(state.cumulativeWeightedReturns, mode);
    },
  getWeightedPerformanceStats:
    (state) =>
    ({ mode }) => {
      return getNested(state.weightedPerformanceData, mode);
    },
  getPerformanceTableData: (state) => {
    return state.performanceTableData;
  },
  getLastLivePrice:
    (state) =>
    ({ mode }) => {
      return getNested(state.lastLivePrice, mode);
    },
  getAdditionalFollowersInLang: (_, getters) => (lang) => {
    const productConfigs = getters.getProductLangsConfig();
    if (!productConfigs) {
      return productConfigs;
    }

    const productConfig = productConfigs.find((productConfig) => productConfig.id === lang);
    if (!productConfig) {
      return undefined;
    }

    return productConfig.additionalFollowers ?? [];
  },
  getProductConfig: (state) => (lang, fallbackLang) => {
    if (!state.productConfig && !state.productLangsConfig) {
      return null;
    }

    // Little helper function to remove null values, hence avoiding
    // cases where we have an element in the global section (e.g.
    // general: {key1: value1}) and a null element in the lang section
    // (e.g. general: null) which would result in an empty key after
    // merge (e.g. general:null instead of general: {key1: value1}).
    const removeNullValues = (obj) => {
      if (!obj) return obj;

      return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
    };

    // Try to get the product config in the requested 'lang'. If we can't,
    // try to get it in the 'fallbackLang'.
    let productLangConfig = state.productLangsConfig?.find(
      (productLangConfig) => productLangConfig.id == lang
    );
    if (!productLangConfig) {
      productLangConfig = state.productLangsConfig?.find(
        (productLangConfig) => productLangConfig.id == fallbackLang
      );
    }

    // Note that 'merge' mutate the first object, it's the source.
    // Therefore if we put one of the sate.productConfig, we get a
    // vuex error that we try to mutate an object outside a mutation.
    return merge(
      {},
      removeNullValues(state.productConfig),
      removeNullValues(productLangConfig ?? {})
    );
  },
  getProductLangsConfig: (state) => (langsSubset) => {
    if (!langsSubset) return state.productLangsConfig;

    return state.productLangsConfig.filter((product) => langsSubset.includes(product.id));
  },
};

const mutations = {
  FETCH_DETAILS_SUCCESS: (state, { mode, data }) => {
    Vue.set(state.details, mode, data);
  },
  FETCH_BENCHMARKS_SUCCESS: (state, { data }) => {
    state.benchmarks = data;
  },
  FETCH_VALID_DATES_SUCCESS: (state, { mode, data }) => {
    Vue.set(state.validDates, mode, data);
  },
  FETCH_PERFORMANCE_DATA_SUCCESS: (state, { mode, data }) => {
    Vue.set(state.performanceData, mode, data);
  },
  FETCH_PERFORMANCE_WITH_PERIOD_DATA_SUCCESS: (state, { mode, data }) => {
    Vue.set(state.performanceWithPeriodData, mode, data);
  },
  FETCH_CUMULATIVE_RETURN_PERIODS_FACTSHEET_SUCCESS: (state, { mode, data }) => {
    Vue.set(state.cumulativeReturnPeriodsFactsheet, mode, data);
  },
  FETCH_CUMULATIVE_RETURNS_SUCCESS: (state, { mode, data }) => {
    Vue.set(state.cumulativeReturns, mode, data);
  },
  FETCH_BENCHMARK_PRICES_SUCCESS: (state, { benchmarkSymbol, data }) => {
    Vue.set(state.benchmarkPrices, benchmarkSymbol, data);
  },
  FETCH_LAST_CUMULATIVE_RETURNS_SUCCESS: (state, { mode, period, data }) => {
    const newInnerObj = getNested(state.lastCumReturns, mode) || {};
    if (!data) {
      Vue.set(newInnerObj, period, data);
    } else {
      Vue.set(newInnerObj, period, data[0]);
    }
    Vue.set(state.lastCumReturns, mode, newInnerObj);
  },
  FETCH_DRAWDOWNS_SUCCESS: (state, { mode, data }) => {
    Vue.set(state.drawdowns, mode, data);
  },
  FETCH_OUTPERFORMANCE_SUCCESS: (state, { mode, data }) => {
    Vue.set(state.outperformance, mode, data);
  },
  FETCH_VOLATILITY_SUCCESS: (state, { mode, data }) => {
    Vue.set(state.volatility, mode, data);
  },
  FETCH_BETA_SUCCESS: (state, { mode, data }) => {
    Vue.set(state.beta, mode, data);
  },
  FETCH_YEARLY_CUMULATIVE_RETURNS_SUCCESS: (state, { mode, data }) => {
    Vue.set(state.yearlyCumulativeReturns, mode, data);
  },
  FETCH_MONTHLY_CUMULATIVE_RETURNS_SUCCESS: (state, { mode, data }) => {
    Vue.set(state.monthlyCumulativeReturns, mode, data);
  },
  FETCH_RETURNS_SUCCESS: (state, { mode, data }) => {
    Vue.set(state.returns, mode, data);
  },
  FETCH_RISK_ADJUSTED_SUCCESS: (state, { mode, data }) => {
    Vue.set(state.riskAdjusted, mode, data);
  },
  FETCH_HOLDINGS_DETAILS_SUCCESS: (state, { data }) => {
    state.holdingsDetails = data;
  },
  FETCH_HOLDINGS_SUCCESS: (state, { data }) => {
    state.holdings = data;
  },
  FETCH_CURRENCY_EXPOSURE_SUCCESS: (state, { data }) => {
    state.currencyExposure = data;
  },
  FETCH_CURRENCY_EXPOSURE_OVER_TIME_SUCCESS: (state, { data }) => {
    state.currencyExposureOverTime = data;
  },
  FETCH_ASSET_CLASSES_SUCCESS: (state, { data }) => {
    state.assetClasses = data;
  },
  FETCH_ASSET_CLASSES_OVER_TIME_SUCCESS: (state, { data }) => {
    state.assetClassesOverTime = data;
  },
  FETCH_INSTRUMENT_TYPES_SUCCESS: (state, { data }) => {
    state.instrumentTypes = data;
  },
  FETCH_INSTRUMENT_TYPES_OVER_TIME_SUCCESS: (state, { data }) => {
    state.instrumentTypesOverTime = data;
  },
  FETCH_SECTORS_SUCCESS: (state, { data }) => {
    state.sectors = data;
  },
  FETCH_SECTORS_OVER_TIME_SUCCESS: (state, { data }) => {
    state.sectorsOverTime = data;
  },
  FETCH_REGIONS_SUCCESS: (state, { data }) => {
    state.regions = data;
  },
  FETCH_REGIONS_OVER_TIME_SUCCESS: (state, { data }) => {
    state.regionsOverTime = data;
  },
  FETCH_ASSETS_UNDER_MANAGEMENT_SUCCESS: (state, { data }) => {
    state.assetsUnderManagement = data;
  },
  FETCH_CUMULATIVE_WEIGHTED_RETURNS_SUCCESS: (state, { mode, data }) => {
    Vue.set(state.cumulativeWeightedReturns, mode, data);
  },
  FETCH_WEIGHTED_PERFORMANCE_DATA_SUCCESS: (state, { mode, data }) => {
    Vue.set(state.weightedPerformanceData, mode, data);
  },
  FETCH_PERFORMANCE_TABLE_DATA_SUCCESS: (state, { data }) => {
    state.performanceTableData = data;
  },
  FETCH_LAST_LIVE_PRICE_SUCCESS: (state, { mode, data }) => {
    Vue.set(state.lastLivePrice, mode, data);
  },
  FETCH_PRODUCT_CONFIG_FAILURE: (state) => {
    state.productConfig = undefined;
  },
  RESET_ALL_STATES: (state) => {
    // Reset all the data fetched from the backend.
    state.details = {};
    state.benchmarks = [];
    state.performanceData = {};
    state.performanceWithPeriodData = {};
    state.cumulativeReturnPeriodsFactsheet = {};
    state.cumulativeReturns = {};
    state.lastCumReturns = {};
    state.drawdowns = {};
    state.outperformance = {};
    state.volatility = {};
    state.beta = {};
    state.yearlyCumulativeReturns = {};
    state.monthlyCumulativeReturns = {};
    state.returns = {};
    state.riskAdjusted = {};
    state.holdings = undefined;
    state.currencyExposure = undefined;
    state.currencyExposureOverTime = undefined;
    state.assetClasses = undefined;
    state.assetClassesOverTime = undefined;
    state.instrumentTypes = undefined;
    state.instrumentTypesOverTime = undefined;
    state.sectors = undefined;
    state.sectorsOverTime = undefined;
    state.regions = undefined;
    state.regionsOverTime = undefined;
    state.assetsUnderManagement = undefined;
    state.cumulativeWeightedReturns = {};
    state.weightedPerformanceData = {};
    state.performanceTableData = undefined;
    state.lastLivePrice = {};
  },
};

const actions = {
  async fetchDetails({ commit }, { productId, mode, benchmarkSymbol }) {
    const params = {};
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    if (benchmarkSymbol !== undefined) {
      params["benchmark_symbol"] = benchmarkSymbol;
    }
    try {
      const response = await axios.get(`/api/products/${productId}/details`, {
        params: params,
      });
      commit("FETCH_DETAILS_SUCCESS", {
        mode,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_DETAILS_SUCCESS", {
        mode,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchBenchmarks({ commit }, { productId }) {
    const params = {};
    try {
      const response = await axios.get(`/api/products/${productId}/benchmarks`, {
        params: params,
      });
      commit("FETCH_BENCHMARKS_SUCCESS", {
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_BENCHMARKS_SUCCESS", {
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchValidDates({ commit }, { productId, mode, period }) {
    const params = {};
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    try {
      const response = await axios.get(`/api/products/${productId}/valid-dates`, {
        params: params,
      });
      commit("FETCH_VALID_DATES_SUCCESS", {
        mode,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_VALID_DATES_SUCCESS", {
        mode,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchLastCumulativeReturns({ commit }, { productId, mode, period }) {
    const params = {};
    if (period !== undefined) {
      params["period"] = period;
    }
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    try {
      const response = await axios.get(`/api/products/${productId}/last-cumulative-returns`, {
        params: params,
      });
      commit("FETCH_LAST_CUMULATIVE_RETURNS_SUCCESS", {
        mode,
        period,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_LAST_CUMULATIVE_RETURNS_SUCCESS", {
        mode,
        period,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchPerformanceData({ commit }, { productId, mode, period, benchmarkSymbol, statIds }) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    if (benchmarkSymbol !== undefined) {
      params["benchmark_symbol"] = benchmarkSymbol;
    }
    // Only compute the max range.
    params["periods"] = ["max"];
    if (statIds !== undefined) {
      params["stat_ids"] = statIds;
    }
    try {
      const response = await axios.get(`/api/products/${productId}/performance-data`, {
        params: params,
      });
      commit("FETCH_PERFORMANCE_DATA_SUCCESS", {
        mode,
        period,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_PERFORMANCE_DATA_SUCCESS", {
        mode,
        period,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchPerformanceWithPeriodsData(
    { commit },
    { productId, mode, period, benchmarkSymbol, periods, statIds }
  ) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    if (benchmarkSymbol !== undefined) {
      params["benchmark_symbol"] = benchmarkSymbol;
    }
    if (periods !== undefined) {
      params["periods"] = periods;
    }
    if (statIds !== undefined) {
      params["stat_ids"] = statIds;
    }
    try {
      const response = await axios.get(`/api/products/${productId}/performance-data`, {
        params: params,
      });
      commit("FETCH_PERFORMANCE_WITH_PERIOD_DATA_SUCCESS", {
        mode,
        period,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_PERFORMANCE_WITH_PERIOD_DATA_SUCCESS", {
        mode,
        period,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchCumulativeReturnPeriodsFactsheet(
    { commit },
    { productId, mode, period, benchmarkSymbol, annualizePeriodsBiggerThanOneYear }
  ) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    params["mode"] = mode;
    if (benchmarkSymbol !== undefined) {
      params["benchmark_symbol"] = benchmarkSymbol;
    }
    params["annualize_periods_bigger_than_one_year"] = annualizePeriodsBiggerThanOneYear;
    try {
      const response = await axios.get(
        `/api/products/${productId}/cumulative-return-periods-factsheet`,
        {
          params: params,
        }
      );
      commit("FETCH_CUMULATIVE_RETURN_PERIODS_FACTSHEET_SUCCESS", {
        mode,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_CUMULATIVE_RETURN_PERIODS_FACTSHEET_SUCCESS", {
        mode,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchCumulativeReturns(
    { commit },
    { productId, mode, period, adjusted, benchmarkSymbol, initialValue, withResample }
  ) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (initialValue) {
      params["initial_value"] = initialValue;
    }
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    if (adjusted !== undefined) {
      params["adjusted"] = adjusted;
    }
    if (benchmarkSymbol !== undefined) {
      params["benchmark_symbol"] = benchmarkSymbol;
    }
    if (withResample !== undefined) {
      params["with_resample"] = withResample;
    }
    try {
      const response = await axios.get(`/api/products/${productId}/cumulative-returns`, {
        params: params,
      });
      commit("FETCH_CUMULATIVE_RETURNS_SUCCESS", {
        mode,
        period,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_CUMULATIVE_RETURNS_SUCCESS", {
        mode,
        period,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchBenchmarkPrices({ commit }, { productId, benchmarkSymbol }) {
    const params = {};
    if (benchmarkSymbol !== undefined) {
      params["benchmark_symbol"] = benchmarkSymbol;
    }
    try {
      const response = await axios.get(`/api/products/${productId}/benchmark-prices`, {
        params: params,
      });
      commit("FETCH_BENCHMARK_PRICES_SUCCESS", {
        benchmarkSymbol,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_BENCHMARK_PRICES_SUCCESS", {
        benchmarkSymbol,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchYearlyCumulativeReturns({ commit }, { productId, mode, benchmarkSymbol, period }) {
    const params = {};
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    if (benchmarkSymbol !== undefined) {
      params["benchmark_symbol"] = benchmarkSymbol;
    }
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    try {
      const response = await axios.get(`/api/products/${productId}/yearly-cumulative-returns`, {
        params: params,
      });
      commit("FETCH_YEARLY_CUMULATIVE_RETURNS_SUCCESS", {
        mode,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_YEARLY_CUMULATIVE_RETURNS_SUCCESS", {
        mode,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchMonthlyCumulativeReturns({ commit }, { productId, mode, benchmarkSymbol, period }) {
    const params = {};
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    if (benchmarkSymbol !== undefined) {
      params["benchmark_symbol"] = benchmarkSymbol;
    }
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    try {
      const response = await axios.get(`/api/products/${productId}/monthly-cumulative-returns`, {
        params: params,
      });
      commit("FETCH_MONTHLY_CUMULATIVE_RETURNS_SUCCESS", {
        mode,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_MONTHLY_CUMULATIVE_RETURNS_SUCCESS", {
        mode,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchDrawdowns({ commit }, { productId, mode, period, benchmarkSymbol }) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    if (benchmarkSymbol !== undefined) {
      params["benchmark_symbol"] = benchmarkSymbol;
    }
    try {
      const response = await axios.get(`/api/products/${productId}/drawdowns`, {
        params: params,
      });
      commit("FETCH_DRAWDOWNS_SUCCESS", {
        mode,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_DRAWDOWNS_SUCCESS", {
        mode,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchOutperformanceValues({ commit }, { productId, mode, period, benchmarkSymbol }) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    if (benchmarkSymbol !== undefined) {
      params["benchmark_symbol"] = benchmarkSymbol;
    }
    try {
      const response = await axios.get(`/api/products/${productId}/outperformance`, {
        params: params,
      });
      commit("FETCH_OUTPERFORMANCE_SUCCESS", {
        mode,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_OUTPERFORMANCE_SUCCESS", {
        mode,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchVolatility({ commit }, { productId, mode, period, benchmarkSymbol }) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    if (benchmarkSymbol !== undefined) {
      params["benchmark_symbol"] = benchmarkSymbol;
    }
    try {
      const response = await axios.get(`/api/products/${productId}/volatility`, {
        params: params,
      });
      commit("FETCH_VOLATILITY_SUCCESS", {
        mode,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_VOLATILITY_SUCCESS", {
        mode,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchBeta({ commit }, { productId, mode, period, benchmarkSymbol }) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    if (benchmarkSymbol !== undefined) {
      params["benchmark_symbol"] = benchmarkSymbol;
    }
    try {
      const response = await axios.get(`/api/products/${productId}/beta`, {
        params: params,
      });
      commit("FETCH_BETA_SUCCESS", {
        mode,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_BETA_SUCCESS", {
        mode,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchReturns({ commit }, { productId, mode, period, benchmarkSymbol }) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    if (benchmarkSymbol !== undefined) {
      params["benchmark_symbol"] = benchmarkSymbol;
    }
    try {
      const response = await axios.get(`/api/products/${productId}/returns`, {
        params: params,
      });
      commit("FETCH_RETURNS_SUCCESS", {
        mode,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_RETURNS_SUCCESS", {
        mode,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchRiskAdjusted({ commit }, { productId, mode, period, benchmarkSymbol }) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    if (benchmarkSymbol !== undefined) {
      params["benchmark_symbol"] = benchmarkSymbol;
    }
    try {
      const response = await axios.get(`/api/products/${productId}/risk-adjusted`, {
        params: params,
      });
      commit("FETCH_RISK_ADJUSTED_SUCCESS", {
        mode,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_RISK_ADJUSTED_SUCCESS", {
        mode,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchHoldingsDetails({ commit }, { productId }) {
    const params = {};
    try {
      const response = await axios.get(`/api/products/${productId}/holdings-details`, {
        params: params,
      });
      // If the response status is 204, it means the top holdings are not available.
      if (response.status === 204) {
        commit("FETCH_HOLDINGS_DETAILS_SUCCESS", {
          data: undefined,
        });
      } else {
        commit("FETCH_HOLDINGS_DETAILS_SUCCESS", {
          data: response.data,
        });
      }
    } catch (error) {
      commit("FETCH_HOLDINGS_DETAILS_SUCCESS", {
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchHoldings({ commit }, { productId, latestDate, lang }) {
    const params = {};
    params["latest_date"] = latestDate;
    params["lang"] = lang;
    try {
      const response = await axios.get(`/api/products/${productId}/holdings`, {
        params: params,
      });
      // If the response status is 204, it means the top holdings are not available.
      if (response.status === 204) {
        commit("FETCH_HOLDINGS_SUCCESS", {
          data: undefined,
        });
      } else {
        commit("FETCH_HOLDINGS_SUCCESS", {
          data: response.data,
        });
      }
    } catch (error) {
      commit("FETCH_HOLDINGS_SUCCESS", {
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchCurrencyExposure({ commit }, { productId, latestDate }) {
    const params = {};
    params["latest_date"] = latestDate;
    try {
      const response = await axios.get(`/api/products/${productId}/currency-exposure`, {
        params: params,
      });
      // If the response status is 204, it means the currency exposure is not available.
      if (response.status === 204) {
        commit("FETCH_CURRENCY_EXPOSURE_SUCCESS", {
          data: undefined,
        });
      } else {
        commit("FETCH_CURRENCY_EXPOSURE_SUCCESS", {
          data: response.data,
        });
      }
    } catch (error) {
      commit("FETCH_CURRENCY_EXPOSURE_SUCCESS", {
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchCurrencyExposureOverTime({ commit }, { productId, period }) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }

    try {
      const response = await axios.get(`/api/products/${productId}/currency-exposure-over-time`, {
        params: params,
      });
      // If the response status is 204, it means the currency exposure is not available.
      if (response.status === 204) {
        commit("FETCH_CURRENCY_EXPOSURE_OVER_TIME_SUCCESS", {
          data: undefined,
        });
      } else {
        commit("FETCH_CURRENCY_EXPOSURE_OVER_TIME_SUCCESS", {
          data: response.data,
        });
      }
    } catch (error) {
      commit("FETCH_CURRENCY_EXPOSURE_OVER_TIME_SUCCESS", {
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchAssetClasses({ commit }, { productId, latestDate, lang }) {
    const params = {};
    params["latest_date"] = latestDate;
    params["lang"] = lang;
    try {
      const response = await axios.get(`/api/products/${productId}/asset-classes`, {
        params: params,
      });
      // If the response status is 204, it means the asset classes are not available.
      if (response.status === 204) {
        commit("FETCH_ASSET_CLASSES_SUCCESS", {
          data: undefined,
        });
      } else {
        commit("FETCH_ASSET_CLASSES_SUCCESS", {
          data: response.data,
        });
      }
    } catch (error) {
      commit("FETCH_ASSET_CLASSES_SUCCESS", {
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchAssetClassesOverTime({ commit }, { productId, period, lang }) {
    const params = {};
    params["lang"] = lang;
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }

    try {
      const response = await axios.get(`/api/products/${productId}/asset-classes-over-time`, {
        params: params,
      });
      // If the response status is 204, it means the asset classes are not available.
      if (response.status === 204) {
        commit("FETCH_ASSET_CLASSES_OVER_TIME_SUCCESS", {
          data: undefined,
        });
      } else {
        commit("FETCH_ASSET_CLASSES_OVER_TIME_SUCCESS", {
          data: response.data,
        });
      }
    } catch (error) {
      commit("FETCH_ASSET_CLASSES_OVER_TIME_SUCCESS", {
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchInstrumentTypes({ commit }, { productId, latestDate, lang }) {
    const params = {};
    params["latest_date"] = latestDate;
    params["lang"] = lang;
    try {
      const response = await axios.get(`/api/products/${productId}/instrument-types`, {
        params: params,
      });
      // If the response status is 204, it means the instrument types are not available.
      if (response.status === 204) {
        commit("FETCH_INSTRUMENT_TYPES_SUCCESS", {
          data: undefined,
        });
      } else {
        commit("FETCH_INSTRUMENT_TYPES_SUCCESS", {
          data: response.data,
        });
      }
    } catch (error) {
      commit("FETCH_INSTRUMENT_TYPES_SUCCESS", {
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchInstrumentTypesOverTime({ commit }, { productId, period, lang }) {
    const params = {};
    params["lang"] = lang;
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }

    try {
      const response = await axios.get(`/api/products/${productId}/instrument-types-over-time`, {
        params: params,
      });
      // If the response status is 204, it means the instrument types are not available.
      if (response.status === 204) {
        commit("FETCH_INSTRUMENT_TYPES_OVER_TIME_SUCCESS", {
          data: undefined,
        });
      } else {
        commit("FETCH_INSTRUMENT_TYPES_OVER_TIME_SUCCESS", {
          data: response.data,
        });
      }
    } catch (error) {
      commit("FETCH_INSTRUMENT_TYPES_OVER_TIME_SUCCESS", {
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchSectors({ commit }, { productId, latestDate, lang }) {
    const params = {};
    params["latest_date"] = latestDate;
    params["lang"] = lang;
    try {
      const response = await axios.get(`/api/products/${productId}/sectors`, {
        params: params,
      });
      // If the response status is 204, it means the sectors are not available.
      if (response.status === 204) {
        commit("FETCH_SECTORS_SUCCESS", {
          data: undefined,
        });
      } else {
        commit("FETCH_SECTORS_SUCCESS", {
          data: response.data,
        });
      }
    } catch (error) {
      commit("FETCH_SECTORS_SUCCESS", {
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchSectorsOverTime({ commit }, { productId, period, lang }) {
    const params = {};
    params["lang"] = lang;
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }

    try {
      const response = await axios.get(`/api/products/${productId}/sectors-over-time`, {
        params: params,
      });
      // If the response status is 204, it means the sectors are not available.
      if (response.status === 204) {
        commit("FETCH_SECTORS_OVER_TIME_SUCCESS", {
          data: undefined,
        });
      } else {
        commit("FETCH_SECTORS_OVER_TIME_SUCCESS", {
          data: response.data,
        });
      }
    } catch (error) {
      commit("FETCH_SECTORS_OVER_TIME_SUCCESS", {
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchRegions({ commit }, { productId, latestDate, lang }) {
    const params = {};
    params["latest_date"] = latestDate;
    params["lang"] = lang;
    try {
      const response = await axios.get(`/api/products/${productId}/regions`, {
        params: params,
      });
      // If the response status is 204, it means the regions are not available.
      if (response.status === 204) {
        commit("FETCH_REGIONS_SUCCESS", {
          data: undefined,
        });
      } else {
        commit("FETCH_REGIONS_SUCCESS", {
          data: response.data,
        });
      }
    } catch (error) {
      commit("FETCH_REGIONS_SUCCESS", {
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchRegionsOverTime({ commit }, { productId, period, lang }) {
    const params = {};
    params["lang"] = lang;
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }

    try {
      const response = await axios.get(`/api/products/${productId}/regions-over-time`, {
        params: params,
      });
      // If the response status is 204, it means the regions are not available.
      if (response.status === 204) {
        commit("FETCH_REGIONS_OVER_TIME_SUCCESS", {
          data: undefined,
        });
      } else {
        commit("FETCH_REGIONS_OVER_TIME_SUCCESS", {
          data: response.data,
        });
      }
    } catch (error) {
      commit("FETCH_REGIONS_OVER_TIME_SUCCESS", {
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchAssetsUnderManagement({ commit }, { productId, latestDate }) {
    const params = {};
    params["latest_date"] = latestDate;
    try {
      const response = await axios.get(`/api/products/${productId}/assets-under-management`, {
        params: params,
      });
      // If the response status is 204, it means the aum is not available.
      if (response.status === 204) {
        commit("FETCH_ASSETS_UNDER_MANAGEMENT_SUCCESS", {
          data: undefined,
        });
      } else {
        commit("FETCH_ASSETS_UNDER_MANAGEMENT_SUCCESS", {
          data: response.data,
        });
      }
    } catch (error) {
      commit("FETCH_ASSETS_UNDER_MANAGEMENT_SUCCESS", {
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchCumulativeWeightedReturns({ commit }, { productId, mode, period, benchmarkSymbol }) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    if (benchmarkSymbol !== undefined) {
      params["benchmark_symbol"] = benchmarkSymbol;
    }
    try {
      const response = await axios.get(`/api/products/${productId}/cumulative-weighted-returns`, {
        params: params,
      });
      commit("FETCH_CUMULATIVE_WEIGHTED_RETURNS_SUCCESS", {
        mode,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_CUMULATIVE_WEIGHTED_RETURNS_SUCCESS", {
        mode,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchPerformanceTableData({ commit }, { productId }) {
    try {
      const response = await axios.get(`/api/products/${productId}/performance-table-data`);
      commit("FETCH_PERFORMANCE_TABLE_DATA_SUCCESS", {
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_PERFORMANCE_TABLE_DATA_SUCCESS", {
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchWeightedPerformanceData(
    { commit },
    { productId, mode, period, benchmarkSymbol, statIds }
  ) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    if (benchmarkSymbol !== undefined) {
      params["benchmark_symbol"] = benchmarkSymbol;
    }
    if (statIds !== undefined) {
      params["stat_ids"] = statIds;
    }
    try {
      const response = await axios.get(`/api/products/${productId}/weighted-performance-data`, {
        params: params,
      });
      commit("FETCH_WEIGHTED_PERFORMANCE_DATA_SUCCESS", {
        mode,
        period,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_WEIGHTED_PERFORMANCE_DATA_SUCCESS", {
        mode,
        period,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchLastLivePrice({ commit }, { productId, latestDate, mode }) {
    const params = {};
    if (latestDate !== undefined) {
      params["latest_date"] = latestDate;
    }
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    try {
      const response = await axios.get(`/api/products/${productId}/last-live-price`, {
        params: params,
      });
      commit("FETCH_LAST_LIVE_PRICE_SUCCESS", {
        mode,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_LAST_LIVE_PRICE_SUCCESS", {
        mode,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  bindProductLangsConfig: firestoreAction(({ bindFirestoreRef, state }, { productId }) => {
    // Binding an already bound state is expensive, likely
    // because of an internal implementation that unbinds first
    // and then rebinds. It's why we avoid rebinding if it is
    // already bound.
    if (state.productLangsConfig != null) {
      return Promise.resolve();
    }

    return bindFirestoreRef(
      "productLangsConfig",
      firebase.firestore().collection("products").doc(productId).collection("langs")
    );
  }),
  bindProductConfig: firestoreAction(({ bindFirestoreRef, state }, { productId }) => {
    // Binding an already bound state is expensive, likely
    // because of an internal implementation that unbinds first
    // and then rebinds. It's why we avoid rebinding if it is
    // already bound.
    if (state.productConfig != null) {
      return Promise.resolve();
    }

    return bindFirestoreRef(
      "productConfig",
      firebase.firestore().collection("products").doc(productId)
    );
  }),
  updateProductConfig: firestoreAction((_, { productId, newProductConfig, lang }) => {
    if (lang) {
      return firebase
        .firestore()
        .collection("products")
        .doc(productId)
        .collection("langs")
        .doc(lang)
        .set(newProductConfig, { merge: true });
    }

    return firebase
      .firestore()
      .collection("products")
      .doc(productId)
      .set(newProductConfig, { merge: true });
  }),
  updateProductConfigFactsheetConfig: firestoreAction(
    (_, { productId, newFactsheetConfig, lang }) => {
      return firebase
        .firestore()
        .collection("products")
        .doc(productId)
        .collection("langs")
        .doc(lang)
        .set(
          {
            factsheet: newFactsheetConfig,
          },
          { merge: true }
        );
    }
  ),
  updateProductConfigOverviewConfig: firestoreAction(
    (_, { productId, newOverviewConfig, lang }) => {
      return firebase
        .firestore()
        .collection("products")
        .doc(productId)
        .collection("langs")
        .doc(lang)
        .set(
          {
            overview: newOverviewConfig,
          },
          { merge: true }
        );
    }
  ),
  updateProductConfigGeneralGlobal: firestoreAction((_, { productId, newGeneralGlobal }) => {
    return firebase.firestore().collection("products").doc(productId).set(
      {
        general: newGeneralGlobal,
      },
      { merge: true }
    );
  }),
  updateProductConfigGeneralLang: firestoreAction((_, { productId, newGeneralLang, lang }) => {
    return firebase
      .firestore()
      .collection("products")
      .doc(productId)
      .collection("langs")
      .doc(lang)
      .set(
        {
          general: newGeneralLang,
        },
        { merge: true }
      );
  }),
  updateProductConfigAccessRestrictions: firestoreAction(
    (_, { productId, newAccessRestrictions }) => {
      return firebase.firestore().collection("products").doc(productId).set(
        {
          accessRestrictions: newAccessRestrictions,
        },
        { merge: true }
      );
    }
  ),
  addAdditionalFollowers: firestoreAction((_, { productId, emails, lang }) => {
    return firebase
      .firestore()
      .collection("products")
      .doc(productId)
      .collection("langs")
      .doc(lang)
      .set(
        {
          additionalFollowers: firebase.firestore.FieldValue.arrayUnion(...emails),
        },
        { merge: true }
      );
  }),
  removeAdditionalFollower: firestoreAction((_, { productId, email, lang }) => {
    return firebase
      .firestore()
      .collection("products")
      .doc(productId)
      .collection("langs")
      .doc(lang)
      .set(
        {
          additionalFollowers: firebase.firestore.FieldValue.arrayRemove(email),
        },
        { merge: true }
      );
  }),
  resetAllStates({ commit }) {
    commit("RESET_ALL_STATES");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
