export default {
  admin: {
    disclaimer: "Haftungsausschluss",
    enterText: "Geben Sie einen Text ein",
    operations: {
      bulkPriceDataUpdate: {
        benchmarks: "Benchmarks",
        benchmarkName: "Benchmark-Name",
        products: "Produkte",
        productName: "Produktname",
        metaInfo: {
          title: "Admin - Operationen - Massen-Preisaktualisierung",
        },
        dashboard: {
          createdAt: "Erstellt am",
          createdBy: "Erstellt von",
          downloadFile: "Datei herunterladen",
          fileDownloadError: "Fehler beim Herunterladen des Dokuments, bitte kontaktieren Sie uns.",
          loadingOperations: "Operationen werden geladen ...",
          newUpdate: "Neue Aktualisierung...",
          refreshButton: "Aktualisieren",
          status: "Status",
          uploadedFile: "Hochgeladene Datei",
          statuses: {
            failed: "Fehlgeschlagen",
            pending: "Ausstehend",
            running: "Läuft",
            succeeded: "Erfolgreich",
          },
        },
        dryRunError: {
          backToUploadCsvButton: "Zurück zum CSV-Upload",
          csvParsingErrorTitle: "CSV-Parsing-Fehler",
          csvParsingErrorText:
            "Die bereitgestellte Datei ist entweder keine gültige CSV-Datei oder hat das falsche Format. Bitte stellen Sie sicher, dass alle Zeilen Werte enthalten, die Spalten korrekt geordnet sind und keine Werte fehlen.",
          numberFormatErrorTitle: "Fehler im Zahlenformat",
          numberFormatErrorText:
            "Die Zahlen in Ihrer Datei konnten nicht analysiert werden. Bitte stellen Sie sicher, dass die Zahlen das richtige Format haben: Verwenden Sie nur Standard-Ziffernzeichen und einen Punkt (.) für Dezimalstellen. Vermeiden Sie andere Sonderzeichen.",
          duplicateDateErrorTitle: "Fehler: Doppelte Daten",
          duplicateDateErrorText: `Die folgenden IDs enthalten doppelte Daten. Jede ID sollte für jede Eingabe eindeutige Daten haben.`,
          unknownIdsTitle: "Unbekannte IDs",
          unknownIdsText: `Die folgenden IDs sind nicht erkannt. Bitte verwenden Sie nur gültige IDs aus den Tabellen auf dieser Seite.`,
          unauthorizedTitle: "Unbefugte IDs",
          unauthorizedText: `Die folgenden IDs sind für Aktualisierungen nicht erlaubt.`,
        },
        preparationAndDryRun: {
          backToDashboardButton: "Zurück zum Dashboard",
          idToUseInCsv: "ID zur Verwendung im CSV",
          uploadCsvForPreview: "CSV für Vorschau hochladen...",
          title: "CSV für Datenvorschau hochladen",
          csvUploadDialog: {
            acceptText: "Vorschau erstellen",
            title: "CSV für Vorschau hochladen",
            text: "Wählen Sie eine CSV-Datei, um die Preis- und Benchmark-Aktualisierungen anzuzeigen. Dieser Testlauf simuliert die Änderungen, sodass Sie diese auf dem nächsten Bildschirm überprüfen können, bevor Sie sie abschließen.",
          },
          explanation: {
            el1: 'Die ID-Spalte sollte Werte enthalten, die Sie in der Spalte "ID zur Verwendung im CSV" in den folgenden Tabellen finden.',
            el2: "Die DATE-Spalte sollte dem Format JJJJ-MM-TT folgen (z. B. 2023-10-15, 1999-01-31).",
            el3: "Der PRICE muss ein Dezimalwert sein.",
            para1:
              "Um Preise und Benchmarks in Massen zu aktualisieren, laden Sie bitte eine CSV-Datei mit drei Spalten hoch: ID, DATE und PRICE. Die Reihenfolge und die Anwesenheit der Kopfzeilen müssen beachtet werden.",
            para2:
              "Alle Preise, die Sie aktualisieren dürfen, seien es die Preise eines Produkts oder einer Benchmark, werden in der/den unten stehenden Tabelle(n) angezeigt. Wenn etwas, das Sie zu sehen erwarten, fehlt, kontaktieren Sie uns bitte.",
            tip: '<em>Tipp</em>: Klicken Sie <a href="/sample-bulk-price-data-update.csv" download><u>hier</u></a>, um eine Beispiel-CSV-Datei als Referenz herunterzuladen.',
          },
        },
        reviewAndConfirm: {
          backToUploadCsvButton: "Zurück zum CSV-Upload",
          confirmAndApplyChangesButton: "Änderungen bestätigen und anwenden",
          firstDate: "Erstes Datum",
          id: "ID",
          lastDate: "Letztes Datum",
          name: "Name",
          numberOfRows: "Anzahl der Zeilen",
          reviewAndConfirmText:
            "Im Folgenden finden Sie eine Übersicht über die geplanten Aktualisierungen. Bitte beachten Sie, dass die zur Aktualisierung vorgesehenen Preise die bestehenden Preise vollständig ersetzen, da die alten Preise gelöscht werden, bevor die neuen eingefügt werden. Sobald Sie die Änderungen überprüft haben, bestätigen Sie bitte unten.",
          title: "Datenaktualisierungen überprüfen und bestätigen",
          successDialog: {
            title: "Operation angelaufen",
            text: "Die Operation wurde erfolgreich angelaufen!",
          },
        },
      },
      generateFactsheets: {
        allLanguages: "Alle Sprachen",
        allProducts: "Alle Produkte",
        createdAt: "Erstellt am",
        factsheetGeneration: "Factsheet-Generierung",
        factsheetGenerationFilters: "Factsheet-Generierungsfilter",
        fileName: "Dateiname",
        generationNotPossibleDate:
          "Das ausgewählte späteste Datum liegt vor dem Erstellungsdatum des Produkts.",
        generationNotPossibleLang: "Diese Sprache ist für das ausgewählte Produkt nicht verfügbar.",
        generationPossible: "Factsheet-Generierung ist möglich.",
        generationWarningFileExists:
          "Eine Datei mit diesem Namen und dieser Sprache existiert bereits. Fortfahren überschreibt die vorhandene Datei.",
        generateSelectedFactsheets: "Ausgewählte Factsheets generieren",
        generationStatus: "Generierungsstatus",
        backToDashboardButton: "Zurück zum Dashboard",
        backToFiltersButton: "Zurück zu den Filtern",
        language: "Sprache",
        lastDateToInclude: "Letzter Termin für die Aufnahme",
        lastDayOfData: "Letzter Tag der Daten",
        loadingOperations: "Lade Operationen ...",
        newButton: "Neue Factsheets generieren...",
        preparingFactsheetsGeneration: "Vorbereitung der Liste der Factsheets zur Generierung...",
        product: "Produkt",
        refreshButton: "Aktualisieren",
        requireLogin: "Login für den Zugriff erforderlich",
        selectFactsheetsForGeneration: "Factsheets zur Generierung auswählen",
        selectLanguages: "Sprachen auswählen",
        selectProducts: "Produkte auswählen",
        setAsLastFactsheet: "Als letztes Factsheet für das Produkt festlegen",
        showAvailableFactsheet: "Verfügbare Factsheets anzeigen",
        status: "Status",
        langMultiselect: {
          placeholder: "Zu berücksichtigende Sprachen auswählen",
          selectLabel: "Drücken Sie die Eingabetaste, um auszuwählen",
          deselectLabel: "Drücken Sie die Eingabetaste, um zu entfernen",
          selectedLabel: "Ausgewählt",
          limitText: (ctx) => `und ${ctx.named("count")} weitere`,
        },
        metaInfo: {
          title: "Admin - Operationen - Factsheets generieren",
        },
        productsMultiselect: {
          placeholder: "Zu berücksichtigende Produkte auswählen",
          selectLabel: "Drücken Sie die Eingabetaste, um auszuwählen",
          deselectLabel: "Drücken Sie die Eingabetaste, um zu entfernen",
          selectedLabel: "Ausgewählt",
          limitText: (ctx) => `und ${ctx.named("count")} weitere`,
        },
        statuses: {
          failed: "Fehlgeschlagen",
          pending: "Ausstehend",
          running: "Läuft",
          succeeded: "Erfolgreich",
        },
        successDialog: {
          title: "Operation erstellt",
          text: "Die Operation wurde erfolgreich gestartet!",
        },
      },
      bulkComponentUpdate: {
        action: "Aktion",
        actionIcons: {
          add: "Hinzufügen",
          edit: "Bearbeiten",
          error: "Fehler",
        },
        backToDashboardButton: "Zurück zum Dashboard",
        bulkUpdate: "CSV hochladen und die möglichen Änderungen sehen...",
        bulkUpdateShortDesc:
          "Hinzufügen oder Aktualisieren generischer Komponenten durch Importieren von CSV von Ihrem Gerät",
        bulkUpdateDesc: {
          action:
            "Bitte stellen Sie die CSV-Datei zum Aktualisieren oder Hinzufügen der generischen Komponenten zur Verfügung.",
          instructions:
            "Die CSV-Datei sollte 7 Spalten enthalten, die in dieser Reihenfolge der Produkt-ID, der Komponenten-ID, dem Komponententyp, der Sprache, dem Schlüssel, dem Wert und der Seite entsprechen.",
          trigger:
            "Nachdem Sie die CSV-Datei hochgeladen haben, können Sie die hinzugefügten oder geänderten Komponenten überprüfen. Wenn Sie mit den vorgeschlagenen Änderungen zufrieden sind, können Sie bestätigen, dass die Aktualisierungen der Komponenten abgeschlossen sind.",
        },
        componentId: "ID der Komponente",
        createdAt: "Erstellt am",
        createdBy: "Erstellt von",
        errorsIntro: "Hier ist die Liste der Regeln, gegen die die CSV verstößt:",
        errorListTitle: "Bereitgestellte CSV-Datei enthält Fehler",
        importing: {
          sucessfulImport: "Die Komponenten werden aktualisiert.",
          uknownFailure: "Der Import von Komponenten ist fehlgeschlagen.",
        },
        importComponentsButton: "Bestätigen und Anwenden von Änderungen",
        key: "Schlüssel",
        lang: "Sprache",
        loadingOperations: "Laden der Operationen...",
        loadingPreview: "Warten auf das Laden von Änderungen...",
        metaInfo: {
          title: "Admin - Operations - Bulk Components Update",
        },
        numComponents: "Anzahl der Komponenten",
        numElements: "Anzahl der Elemente",
        page: "Teil",
        previewTitle: "Vorschau auf die Änderungen, die vorgenommen werden",
        product: "Produkt",
        rules: {
          allowedLang:
            "Bei der Sprache sollte es sich um eine bereits vorhandene Sprache für das entsprechende Produkt handeln.",
          columns:
            "Es sollten 7 Spalten in der folgenden Reihenfolge vorhanden sein: Produkt-ID, Komponenten-ID, Komponententyp, Sprache, Schlüssel, Wert, Teil.",
          invalidFile:
            "CSV-Datei kann nicht geparst werden. Stellen Sie sicher, dass Sie die richtige CSV-Datei haben.",
          keyDuplication: "Die Schlüssel können nicht dupliziert werden.",
          linking: "Verknüpfte Komponenten können nicht gleichzeitig aktualisiert werden.",
          multipleTypes: "Mehrere Typen für dieselbe Komponente angegeben.",
          naValue: "Die bereitgestellte CSV-Datei darf keine NaN-Werte enthalten.",
          nonEditableComponent:
            "Eine Komponente kann nicht bearbeitet werden, da es sich um eine vordefinierte Komponente handelt oder der angegebene Komponententyp falsch ist.",
          nonFloatValue: "Bei Diagrammen muss die Wertespalte Zahlen enthalten.",
          numRowsPerLang: "Für jede Sprache sollte es die gleiche Anzahl von Zeilen geben.",
          productExists: "Die Produkt-IDs müssen gültig sein.",
          unsupportedPage: "Die zulässigen Komponentenseiten sind: 'overview' und 'factsheet'.",
          unsupportedType:
            "Die zulässigen Komponententypen sind: 'bar-chart', 'pie-chart', 'donut-chart', 'table'",
        },
        type: "Typ",
        value: "Wert",
      },
      overrideHoldingData: {
        exportCsv: "CSV der Bestandsdaten exportieren",
        importCsv: "CSV mit Überschreibungen importieren",
        onlyShowOverride: "Nur Zeilen mit geänderten Werten anzeigen:",
        override: (ctx) => `Überschreibt: ${ctx.named("value")}`,
        saveChanges: "Alle Änderungen speichern",
        saveSuccess: "Die Änderungen der Positionsdaten wurden erfolgreich gespeichert.",
        textUniqueKeys:
          "Diese Tabelle zeigt die Basiswerte der Positionsdaten für alle auf der Plattform verfügbaren Produkte an. Jeder eindeutige Eintrag wird durch eine Kombination aus 'Positionsname', 'Währung' und 'ISIN' definiert. Wenn dieser eindeutige Schlüssel in mehreren Produkten verwendet wird, wird er hier nur einmal angezeigt.",
        textHowToSingleOverride:
          "Um geänderte Werte für eine Zeile anzuzeigen oder zu ändern, klicken Sie darauf, um die Details anzuzeigen. Änderungen, die sich von den Basiswerten unterscheiden, werden hervorgehoben: Grüne Texte zeigen Änderungen, die bereits gespeichert wurden, und gelbe Texte zeigen Änderungen, die noch nicht gespeichert sind.",
        textHowToBulkOverride:
          "Wenn Sie Massenänderungen vornehmen möchten, empfiehlt es sich, die aktuellen Positionsdaten als CSV-Datei herunterzuladen. Sie können die Änderungen dann mit einem Tabellenkalkulationsprogramm (z. B. Microsoft Excel) vornehmen und die bearbeitete CSV-Datei hier erneut hochladen. Jede hochgeladene CSV-Datei zeigt zunächst eine Vorschau der vorgeschlagenen Änderungen, die Sie speichern müssen, um sie anzuwenden.",
        title: "Positionsdaten mit Änderungen",
        unsavedChangesAlert:
          "Sie haben ungespeicherte Änderungen. Wenn Sie die Seite verlassen, ohne zu speichern, gehen diese verloren.",
        columns: {
          assetClass: "Asset-Klasse",
          assetClassOverride: "Geänderte Asset-Klasse",
          currency: "Währung",
          holdingName: "Positionsname",
          holdingNameOverride: "Geänderter Positionsname",
          instrumentType: "Instrumententyp",
          instrumentTypeOverride: "Geänderter Instrumententyp",
          invalidValue: "Ungültiger Wert",
          isin: "ISIN",
          isinOverride: "Geändertes ISIN",
          region: "Region",
          regionOverride: "Geänderte Region",
          rowIndex: "Zeilenindex",
          sector: "Sektor",
          sectorOverride: "Geänderter Sektor",
        },
        uploadCsvErrorsDialog: {
          close: "Schließen",
          nonExistentAssetClass: "Nicht vorhandene Asset-Klasse",
          nonExistentKey: "Nicht vorhandene Schlüssel",
          nonExistentInstrumentType: "Nicht vorhandener Instrumententyp",
          nonExistentRegion: "Nicht vorhandene Region",
          nonExistentSector: "Nicht vorhandener Sektor",
          selectErrorType: "Fehlertyp auswählen:",
          text: "Die von Ihnen bereitgestellte CSV-Datei enthält Fehler. Wenn nicht vorhandene Schlüssel (eine Kombination aus Positionsname, ISIN und Währung) erkannt werden, überprüfen Sie bitte auf Tippfehler. Wenn unbekannte Werte in den Spalten 'assetClassOverride', 'instrumentTypeOverride', 'sectorOverride' oder 'regionOverride' gefunden werden, überprüfen Sie bitte die verfügbaren Werte für diese Spalten.",
          title: "CSV-Validierungsfehler",
        },
        uploadCsvDialog: {
          cancel: "Abbrechen",
          import: "Importieren",
          text: "Bitte importieren Sie eine CSV-Datei im gewünschten Format. Sie können die CSV-Datei exportieren, um die erwarteten Spalten anzuzeigen.",
          title: "CSV mit Überschreibungen importieren",
        },
      },
    },
    platformSettings: {
      platformSettings: "Plattformeinstellungen",
      complianceTab: {
        checksActivated: "Prüfungen aktiviert",
        editTitle: "Compliance bearbeiten",
        title: "Compliance",
        investorProfile: {
          disclaimerTooltip:
            "Dieser Text wird Benutzern angezeigt, die auf die Plattform kommen und nach ihrem Anlegerprofil gefragt werden (Typ und Land). Stellen Sie sicher, dass Sie einen angemessenen und rechtlichen Haftungsausschluss verfassen.",
          goToInvestorProfilePage: "Seite öffnen",
          seeInvestorProfilePage: "Siehe Anlegerprofil Seite",
          showLogoOnInvestorProfile: "Logo auf Anlegerprofilseite anzeigen",
          title: "Anlegerprofil",
          tooltip:
            'Sie können die Prüfungen des Anlegerprofils aktivieren, was bedeutet, dass ein Benutzer, der zum ersten Mal auf Ihre Plattform kommt, seinen Anlegertyp (Privatanleger, professioneller Anleger, institutioneller Anleger) sowie sein Land auswählen muss. Sie können dann konfigurieren, welcher Anlegertyp und aus welchem Land Zugang zu Ihren Produkten/Anteilsklassen hat; dies kann im Admin>Produkte Bereich unter der Registerkarte "Zugriffsbeschränkungen" erfolgen, sobald Sie die Prüfungen hier aktiviert haben.',
        },
        notify: {
          saveSuccessText: "Ihre Compliance-Einstellungen wurden erfolgreich aktualisiert.",
          saveSuccessTextWithLang: (ctx) =>
            `Ihre Compliance-Einstellungen wurden erfolgreich aktualisiert (Sprache: ${ctx.named(
              "lang"
            )}).`,
        },
        ombudsman: {
          title: "Ombudsman-Text",
          tooltip:
            "Wenn Sie auf dieser Plattform auf Ihren Ombudsmann verweisen müssen, schreiben Sie den Text, der den Nutzern angezeigt wird, die Ihren Ombudsmann aufsuchen. Ein Link zu diesem Text wird in der Fußzeile der Plattform neben der Datenschutzerklärung sichtbar sein.",
        },
        productDisclaimer: {
          title: "Standard-Produkthaftungsausschluss",
          tooltip:
            'Legen Sie einen Standard-Produkthaftungsausschluss fest, der für alle Produkte verwendet wird, die keinen produktspezifischen Haftungsausschluss haben. Sie können einen produktspezifischen Haftungsausschluss festlegen, indem Sie auf "Admin > Produkte" gehen, dann das gewünschte Produkt auswählen und den produktspezifischen Haftungsausschluss auf der Registerkarte "Allgemein" festlegen.',
        },
      },
      rolesTab: {
        editTitle: "Rollen bearbeiten",
        title: "Rollen",
        description:
          'Standardmäßig existiert nur die Rolle "admin", eine Rolle, die Administrationsrechte für alle Produkte sowie Zugang zu den Plattformeinstellungen gewährt. Es kann nützlich sein, feiner abgestimmte Rollen zu haben, zum Beispiel, um einigen Benutzern nur für einige Produkte Administrationsrechte zu geben. Um solche Rollen zu erstellen, verwenden Sie die folgende Tabelle. Beachten Sie, dass Sie, sobald Sie die Rollen gespeichert haben, auf die {0}-Seite gehen müssen, um die Rollen den gewünschten Benutzern zuzuweisen.',
        table: {
          roleNameHeader: "Rollenname",
          productsHeader: "Produkte mit Administrationsrechten",
          removeRoleTooltip: "Rolle entfernen",
          addRoleTooltip: "Rolle hinzufügen",
          productsMultiselect: {
            placeholder: "Produkt auswählen",
            selectLabel: "Enter drücken zum Auswählen",
            deselectLabel: "Enter drücken, um zu entfernen",
            selectedLabel: "Ausgewählt",
            limitText: "und {count} weitere",
          },
        },
        notify: {
          saveSuccessText: "Ihre Rollen wurden erfolgreich aktualisiert.",
        },
      },
      metaInfo: {
        title: "Admin - Plattformeinstellungen",
      },
    },
    products: {
      contactUsToFixError:
        "Bitte kontaktieren Sie uns über das Kontaktformular, um diesen Fehler zu beheben!",
      langTabError: "Einige Felder sind ungültig in der Sprache:",
      overwriteDataAllShareClasses: "Überschreiben Sie die Daten aller Anteilsklassen",
      tabError: "Einige Felder sind ungültig",
      unsavedChangesAlert:
        "Sie haben ungespeicherte Änderungen. Wenn Sie die Seite verlassen, ohne zu speichern, gehen diese verloren.",
      accessRestrictionsTab: {
        allCountries: "Alle Länder",
        countriesAllowedForInstitutionalInvestors: "Zugelassene Länder für institutionelle Anleger",
        countriesAllowedForProfessionalInvestors: "Zugelassene Länder für professionelle Anleger",
        countriesAllowedForRetailInvestors: "Zugelassene Länder für Privatanleger",
        editAccessRestrictions: "Zugangsbeschränkungen bearbeiten",
        title: "Zugangsbeschränkungen",
        warningEnablingAccessRestrictions:
          'Um die Zugangsbeschränkungen ändern zu können, aktivieren Sie bitte die Anlegerprofilprüfungen in den {0}, unter dem Tab "Compliance".',
      },
      contactsTab: {
        addAnyPerson: "Fügen Sie Personen hinzu, die auf Ihrem Factsheet angezeigt werden sollen.",
        additionalText: "Zusätzlicher Text",
        email: "E-Mail",
        imageSquare: "Bild (quadratisch)",
        name: "Name",
        phone: "Telefon",
        role: "Rolle",
        title: "Kontakte",
      },
      detailsTab: {
        editDetails: "Details bearbeiten",
        key: "Schlüssel",
        showOnFactsheet: "Auf Factsheet anzeigen",
        title: "Details",
        value: "Wert",
      },
      dialog: {
        saveFieldInAllShareClasses: {
          title: "Überschreiben",
          text: "Sie werden dieses Feld in allen anderen Anteilsklassen dieses Produkts überschreiben, nicht nur in der aktuellen Anteilsklasse. Sind Sie sicher?",
        },
        saveInAllShareClasses: {
          title: "Überschreiben",
          text: (ctx) =>
            `Sie werden die Daten des "${ctx.named(
              "tabName"
            )}" für alle anderen Anteilsklassen dieses Produkts mit den Daten des "${ctx.named(
              "tabName"
            )}" dieser Anteilsklasse überschreiben (nicht nur die letzten Änderungen, die Sie vorgenommen haben). Sind Sie sicher?`,
        },
      },
      followersTab: {
        addedByAdmin: "Vom Administrator hinzugefügt",
        addFollowerButton: "Einen Follower hinzufügen",
        email: "E-Mail",
        exportCsv: "Als CSV exportieren",
        importCsv: "Aus CSV importieren",
        noDataText: "Niemand folgt diesem Produkt",
        product: "Produkt",
        removeFollowerTooltip: "Follower für das entsprechende Produkt entfernen",
        sendEmail: "Eine E-Mail senden",
        showAnyShareClass: "Follower von beliebigen Anteilsklassen anzeigen",
        title: "Follower",
        addFollowerPrompt: {
          email: "E-Mail des Followers",
          emailAlreadyExists: "Jemand mit dieser E-Mail-Adresse folgt diesem Produkt bereits.",
          file: "CSV-Datei",
          language: "Sprache des Followers",
          text: "Bevor Sie manuell einen Follower hinzufügen, müssen Sie sicherstellen, dass dieser damit einverstanden ist, die von Ihnen geplanten Informationen zu erhalten.",
          titleAddFollower: "Follower hinzufügen",
          titleImportFollowers: "Follower importieren",
        },
        notify: {
          newFollowerAddedSuccess: "Der neue Follower wurde erfolgreich hinzugefügt.",
          newFollowerAddedError:
            "Beim Hinzufügen des neuen Followers ist ein Problem aufgetreten. Versuchen Sie es erneut oder kontaktieren Sie uns, wenn das Problem weiterhin besteht.",
          noDataInCsv:
            "Keine E-Mail hinzuzufügen (nach dem Filtern der bereits vorhandenen E-Mail(s)).",
          noEmailHeader:
            "In Ihrer Datei fehlt eine Spaltenüberschrift für die E-Mails. Akzeptierte Spaltenüberschriften sind die folgenden (Groß- und Kleinschreibung wird nicht berücksichtigt): email oder e-mail.",
          followerRemovedSuccess: "Der Follower wurde erfolgreich entfernt.",
          followerRemovedError:
            "Beim Entfernen des Followers ist ein Problem aufgetreten. Versuchen Sie es erneut oder kontaktieren Sie uns, wenn das Problem weiterhin besteht.",
          wrongFormatEmails: (ctx) =>
            `Ihre Datei enthält E-Mail(s) mit falschem Format: ${ctx.named("emails")}`,
        },
        removeFollowerPrompt: {
          acceptText: "Follower entfernen",
          text: (ctx) =>
            `Sind Sie sicher, dass Sie "${ctx.named(
              "followerEmail"
            )}" als Follower für das Produkt "${ctx.named("productName")}" entfernen möchten?`,
          title: "Follower entfernen",
        },
      },
      generalTab: {
        editGeneralField: "Allgemeines Feld bearbeiten",
        hidden: "Versteckt",
        hiddenProductTooltip: "Das Produkt auf der Plattform für Nicht-Admin-Benutzer ausblenden.",
        icon: "Symbol",
        isin: "ISIN",
        title: "Allgemein",
      },
      holdingComponentsTab: {
        barChart: "Balkendiagramm",
        data: "Daten",
        date: "Datum",
        donutChart: "Donutdiagramm",
        header: "Überschrift",
        key: "Schlüssel",
        loadingData: "Daten laden...",
        noData: "Für dieses Produkt gibt es keine Bestandsdaten.",
        pieChart: "Kreisdiagramm",
        shownOnOverview: "Abgebildet auf Übersicht",
        table: "Tabelle",
        title: "Positionskomponenten",
        type: "Typ",
        weightPercent: "Gewicht (in %)",
        componentTitle: {
          assetClasses: "Assetklassen",
          currencyExposure: "Währungsexposition",
          instrumentTypes: "Instrumententypen",
          regions: "Regionen",
          sectors: "Sektoren",
          topHoldings: "Einzelpositionen",
        },
      },
      holdingDataTab: {
        delete: "Löschen",
        deleteSuccessText: "Die Positionsdaten wurden erfolgreich gelöscht.",
        exportCsv: "Als CSV exportieren",
        importCsv: "Aus CSV importieren",
        importSuccessText: "Die Positionsdaten wurden erfolgreich importiert.",
        title: "Positionsdaten",
        loadingData: "Daten werden geladen...",
        noHoldingData: "Für dieses Produkt sind keine Positionsdaten verfügbar.",
        columns: {
          assetClass: "Anlageklasse",
          currency: "Währung",
          holdingName: "Positionsname",
          instrumentType: "Instrumenttyp",
          isin: "ISIN",
          region: "Region",
          sector: "Sektor",
          value: "Wert",
          weight: "Gewichtung",
        },
        deleteHoldingDataDialog: {
          delete: "Löschen",
          text: (ctx) =>
            `Sie sind dabei, die Positionsdaten für das Datum ${ctx.named(
              "date"
            )} vollständig zu löschen. Wenn Sie sicher sind, klicken Sie auf die Schaltfläche unten, um fortzufahren.`,
          title: "Positionsdaten löschen",
        },
        errors: {
          csvParseFail:
            "Die CSV-Datei konnte nicht analysiert werden. Stellen Sie sicher, dass sie korrekt formatiert ist und eine gültige .csv-Erweiterung hat.",
          numberFormat:
            "Einige Zahlen in der Datei konnten nicht verarbeitet werden. Dies könnte auf inkonsistente Dezimaltrennzeichen (z. B. Punkt oder Komma), gemischte Formate oder unerwartete Zeichen in den Spalten zurückzuführen sein. Beachten Sie, dass sowohl die Spalten 'Gewicht' als auch 'Wert' obligatorisch sind und für jede Zeile angegeben werden müssen.",
          missingCurrency:
            "Jede Zeile in der CSV-Datei muss eine Währung enthalten. Bitte stellen Sie sicher, dass alle Zeilen eine gültige Währung haben, und versuchen Sie es erneut.",
          keyColumnsTupleNotUnique:
            "Die ersten drei Spalten (Positionsname, Währung und ISIN) müssen eine Position eindeutig identifizieren. Die bereitgestellte CSV-Datei enthält doppelte Zeilen mit denselben Werten in diesen Spalten. Bitte stellen Sie sicher, dass diese Zeilen eindeutig sind oder zusammengeführt werden.",
          dateAlreadyExists:
            "Das in Ihrer CSV-Datei angegebene Datum ist bereits im System vorhanden. Um fortzufahren, löschen Sie bitte die vorhandenen Daten für dieses Datum über die Benutzeroberfläche und versuchen Sie es erneut.",
          holdingAttributesValidation:
            "Eine oder mehrere Werte in den Spalten 'Anlageklasse', 'Instrumenttyp', 'Sektor' oder 'Region' sind ungültig. Für eine vollständige Liste der akzeptablen Werte wenden Sie sich bitte an den Support.",
        },
        uploadCsvDialog: {
          cancel: "Abbrechen",
          condition1:
            "Die Spalten müssen in folgender Reihenfolge vorliegen: Positionsname, Währung, ISIN, Anlageklasse, Instrumenttyp, Sektor, Region, Gewichtung und Wert. Alle Spalten sind erforderlich; unbekannte Werte sollten leer gelassen werden.",
          condition2:
            "Die Datei muss am Anfang eine Kopfzeile mit den in der vorherigen Bedingung angegebenen Spaltennamen enthalten.",
          condition3: "Die Währungsspalte ist obligatorisch und darf nicht leer gelassen werden.",
          condition4:
            "Jede Zeile muss eine einzigartige Kombination aus Positionsname, Währung und ISIN aufweisen.",
          date: "Datum:",
          import: "Importieren",
          text: "Bitte laden Sie eine CSV-Datei mit den Positionsdaten hoch. Die folgenden Bedingungen müssen erfüllt sein, damit der Import erfolgreich ist:",
          title: "Positionsdaten aus CSV importieren",
        },
        uploadCsvErrorsDialog: {
          close: "Schließen",
          holdingAttribute: "Positionsattribut",
          invalidValue: "Ungültiger Wert",
          rowNumber: "Zeilennummer",
          title: "Fehler in der CSV-Datei für Positionsdaten",
        },
      },
      keyCharacteristicsTab: {
        header: "Überschrift",
        icon: "Symbol",
        paragraph: "Absatz",
        title: "Hauptmerkmale",
      },
      metaInfo: {
        title: "Admin - Produkteinstellungen",
      },
      notify: {
        productSaveSuccess: (ctx) =>
          `Die Konfiguration Ihres Produkts '${ctx.named(
            "productName"
          )}' wurde erfolgreich aktualisiert.`,
        productSaveSuccessWithLang: (ctx) =>
          `Die Konfiguration Ihres Produkts '${ctx.named(
            "productName"
          )}' wurde erfolgreich aktualisiert (Sprache: ${ctx.named("lang")}).`,
      },
      priceDataTab: {
        title: "Preisdaten",
        benchmarks: {
          title: "Benchmarks",
        },
        product: {
          title: "Product",
        },
      },
      selectCard: {
        loadingProducts: "Produkte werden geladen...",
        loadingShareClasses: "Anteilsklassen werden geladen...",
        noProducts: "Sie haben keine Produkte.",
        selectProduct: "Wählen Sie ein Produkt aus",
        selectShareClass: "Wählen Sie eine Anteilsklasse aus",
      },
    },
    users: {
      tabName: "Liste der Benutzer",
      company: "Unternehmen",
      country: "Land",
      email: "E-Mail",
      emailCopied: "Die E-Mail wurde kopiert.",
      investorType: "Anlegertyp",
      name: "Name",
      lastVisit: "Letzter Besuch",
      phone: "Telefon",
      roles: "Rollen",
      metaInfo: {
        title: "Admin - Benutzerliste",
      },
    },
  },
  auth: {
    email: "E-Mail",
    errorAlreadyEmailAndPasswordRegister:
      "Sie sind bereits mit dieser E-Mail und einem Passwort registriert. Bitte melden Sie sich mit dieser E-Mail und Ihrem Passwort an.",
    errorInternal: "Es ist ein interner Fehler aufgetreten. Bitte kontaktieren Sie uns.",
    or: "oder",
    password: "Passwort",
    login: {
      errorAccountNotExist:
        "Sie haben kein Konto auf dieser Plattform, bitte erstellen Sie ein Konto und versuchen Sie es erneut.",
      errorInvalidLoginCredentials:
        "Die angegebenen Anmeldedaten stimmen nicht mit einem unserer Benutzerkonten überein. Diese Diskrepanz könnte auf eine falsche E-Mail oder ein falsches Passwort zurückzuführen sein, auf das Fehlen eines Kontos, das mit der eingegebenen E-Mail verknüpft ist, oder auf die Möglichkeit, dass die E-Mail mit einer alternativen Anmeldemethode (wie Google) verknüpft ist. Bitte überprüfen Sie Ihre Angaben und versuchen Sie es erneut oder erstellen Sie gegebenenfalls ein Konto.",
      errorNeedEmailVerification:
        "Sie müssen Ihre E-Mail-Adresse verifizieren, bevor Sie sich auf unserer Plattform anmelden können. Bitte überprüfen Sie Ihre E-Mails (und Ihren Spam-Ordner), um die E-Mail zu finden. Wenn Sie sie nicht finden können, setzen Sie Ihr Passwort zurück.",
      noAccountYet: "Noch kein Konto?",
      rememberMe: "Angemeldet bleiben",
      welcomeBack: "Willkommen zurück, bitte melden Sie sich bei Ihrem Konto an.",
      dialog: {
        alreadyLoggedInText: "Sie sind bereits angemeldet!",
      },
      metaInfo: { title: "Plattform - Anmeldung" },
      notify: {
        newAccessRight: {
          title: "Neue Zugriffsrechte",
          text: "Sie wurden automatisch abgemeldet, weil Ihnen neue Zugriffsrechte zugewiesen wurden, die eine erneute Anmeldung zur Validierung erfordern.",
        },
        registrationSuccess: {
          title: "Registrierung erfolgreich",
          text: "Registrierung erfolgreich, wir haben Ihnen eine Nachricht zur Validierung Ihrer E-Mail-Adresse geschickt, überprüfen Sie Ihren Posteingang!",
        },
        requiresRecentLogin: {
          title: "Reauthentifizierung erforderlich",
          text: "Um mit dieser Aktion fortzufahren, melden Sie sich bitte erneut an, da Ihre Sitzung abgelaufen ist. Sobald Sie sich erneut authentifiziert haben, können Sie die Aktion erneut versuchen.",
        },
        unexpectedError: {
          title: "Unerwarteter Fehler",
          text: "Ein unerwarteter Fehler ist aufgetreten. Bitte melden Sie sich erneut an. Wenn das Problem weiterhin besteht, wenden Sie sich bitte an den Support.",
        },
      },
    },
    login2fa: {
      enterCode: "Bitte geben Sie den 6-stelligen Code aus Ihrer Authenticator-App ein.",
      incorrectCode: "Der Code ist nicht korrekt. Bitte versuchen Sie es erneut.",
      title: "2-Faktor-Authentifizierung",
      verify: "Überprüfen",
      verifying: "Überprüfen ...",
    },
    register: {
      alreadyRegistered: "Bereits registriert?",
      confirmPassword: "Passwort bestätigen",
      createAccount: "Konto erstellen",
      errorAlreadyEmailAndPasswordRegister:
        "Sie haben bereits ein Konto mit dieser E-Mail. Bitte melden Sie sich bei Ihrem Konto an.",
      errorAlreadyGoogleRegister:
        "Sie sind bereits mit diesem Google-Konto registriert. Bitte melden Sie sich mit diesem Konto an.",
      errorAlreadyMicrosoftRegister:
        "Sie sind bereits mit diesem Microsoft-Konto registriert. Bitte melden Sie sich mit diesem Konto an.",
      fillBelowForm:
        'Füllen Sie das untenstehende Formular aus, um ein neues Konto zu erstellen. Felder, die mit <span class="text-danger">*</span> gekennzeichnet sind, sind erforderlich.',
      registerWith: "Registrieren mit: ",
      form: {
        agreeAdvertisementOnly:
          "Ich stimme ausdrücklich zu, dass alle Informationen auf dieser Plattform ausschließlich zu Informations- und Werbezwecken dienen und nicht als Angebot, Rat oder Empfehlung zum Kauf oder Verkauf eines bestimmten Wertpapiers oder zum Abschluss einer Transaktion ausgelegt werden sollten.",
        certifyAdult: "Ich bestätige, dass ich 18 Jahre alt oder älter bin.",
        company: "Firma",
        confirmPassword: "Passwort bestätigen",
        email: "E-Mail",
        firstName: "Vorname",
        investorProfileCountry: "Land",
        investorProfileType: "Anlegertyp",
        lastName: "Nachname",
        needAgree: "Sie müssen zustimmen",
        phone: "Telefon",
        privacyPolicy: "Datenschutzrichtlinie",
        registeringAccept: "Mit der Registrierung akzeptieren Sie unsere {0} und {1}.",
        termsOfUse: "Nutzungsbedingungen",
      },
      dialog: {
        alreadyLoggedInText: "Sie sind bereits angemeldet!",
      },
      metaInfo: { title: "Plattform - Registrierung" },
    },
    register2fa: {
      generatingQrCode: "Generierung des QR-Codes ...",
      incorrectCode: "Der Code ist nicht korrekt. Bitte versuchen Sie es erneut.",
      need2fa:
        'Um auf die Plattform zuzugreifen, richten Sie bitte eine 2-Faktor-Authentifizierung (2FA) ein. Scannen Sie den unten stehenden QR-Code oder geben Sie den bereitgestellten geheimen Schlüssel in eine Authentifizierungs-App wie "Google Authenticator" oder "Microsoft Authenticator" ein.',
      title: "2-Faktor-Authentifizierung einrichten",
      verify: "Überprüfen",
      verifying: "Überprüfen ...",
    },
    resetPassword: {
      title: "Passwort zurücksetzen",
      sendEmail: "E-Mail senden",
      dialog: {
        internalErrorText: "Ein interner Fehler ist aufgetreten. Bitte kontaktieren Sie uns.",
        resetEmailSent: {
          title: "E-Mail zum Zurücksetzen gesendet",
          text: (ctx) =>
            `Eine E-Mail zum Zurücksetzen wurde an den Posteingang von ${ctx.named(
              "email"
            )} gesendet. Wenn sie nicht in Kürze erscheint, prüfen Sie den Spam-Posteingang.`,
        },
        unknownEmail: {
          title: "E-Mail existiert nicht",
          text: "Die E-Mail, die Sie zum Zurücksetzen des Passworts angegeben haben, existiert nicht. Um ein Konto mit dieser E-Mail zu erstellen, gehen Sie zur Registrierungsseite.",
        },
      },
      metaInfo: { title: "Plattform - Passwort zurücksetzen" },
    },
  },
  compliance: {
    investorProfile: {
      agreeAndConfirmSelection: "Zustimmen und Auswahl bestätigen",
      byClickingAcceptDisclaimer:
        "Indem ich auf die Schaltfläche unten klicke, bestätige ich, dass ich den oben genannten Haftungsausschluss gelesen und akzeptiert habe und den {0} und den {1} dieser Plattform zustimme.",
      pleaseChooseProfile: "Bitte wählen Sie Ihr Herkunftsland und Anlegerprofil, um fortzufahren.",
      privacyPolicy: "Datenschutzrichtlinie",
      termsOfUse: "Nutzungsbedingungen",
      title: "Anlegerprofil",
      metaInfo: {
        title: "Plattform - Anlegerprofil",
      },
    },
    ombudsman: {
      title: "Ombudsmann",
      metaInfo: {
        title: "Plattform - Ombudsmann",
      },
    },
    privacyPolicy: {
      endDate: "Crissier, 31. August 2023",
      title: "Datenschutzrichtlinie",
      metaInfo: { title: "Plattform - Datenschutzrichtlinie" },
      introductionParagraph: {
        title: "Einleitung",
        para1:
          "Alquant SA (nachfolgend als Alquant, 'wir', 'uns' bezeichnet) ist das Unternehmen, das diese Plattform betreibt. Alquant SA respektiert die Privatsphäre der Nutzer seiner Webpräsenz. Dies bedeutet, dass die personenbezogenen Daten gemäß den Bestimmungen des anwendbaren Datenschutzgesetzes und auf der Grundlage dieser Datenschutzrichtlinie erfasst, verarbeitet und genutzt werden.",
        para2:
          "Dies gilt auch - und insbesondere - für die Zusammenarbeit mit Partnern und Dritten, die wir für die Vertragsabwicklung nutzen.",
        para3:
          "Mit der Zustimmung zur folgenden Datenschutzrichtlinie erklären Sie sich mit den nachstehend detaillierten Verfahren von Alquant zur Erfassung, Verarbeitung und Nutzung von Daten gemäß den Datenschutzgesetzen und den folgenden Bestimmungen einverstanden. Diese Zustimmung kann jederzeit mit Wirkung für die Zukunft widerrufen werden.",
        para4:
          "Unsere Datenschutzpraxis steht daher im Einklang mit dem neuen Bundesgesetz über den Datenschutz (DSG) und, soweit anwendbar, mit der EU-Datenschutzgrundverordnung (DSGVO).",
      },
      accountableAuthorityParagraph: {
        title: "Verantwortliche Stelle / EU-Vertreter",
        switzerland: "Schweiz",
        euRepresentativeSub: {
          title: "EU-Vertreter",
          para1:
            "In den in Artikel 3 Absatz 2 der Verordnung (EU) 2016/679 (Datenschutz-Grundverordnung; DSGVO) genannten Fällen und in Übereinstimmung mit Artikel 27 der DSGVO als Vertreter in der EU benannt ist und in Bezug auf die Schweizer nFADP Anforderung einer Datenschutzbeauftragten Funktion:",
          para3: "Sie erreichen unseren EU-Vertreter unter: info(at)alquant.com",
        },
        accountableAuthoritySub: {
          email: "E-Mail",
          phone: "Telefon",
          title: "Verantwortliche Stelle und Datenschutzfunktion nach Swiss nFADP",
          para1: "Verantwortlich für die Verarbeitung personenbezogener Daten ist:",
          para3:
            "Für Anfragen im Zusammenhang mit dem Schutz Ihrer persönlichen Informationen wenden Sie sich bitte an unseren Datenschutzbeauftragten unter: compliance(at)alquant.com.",
        },
      },
      personalDataParagraph: {
        title: "Personenbezogene Daten",
        para1:
          "Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen; eine natürliche Person gilt als identifizierbar, wenn sie direkt oder indirekt identifiziert werden kann, insbesondere durch Bezugnahme auf einen Identifikator wie einen Namen, eine Identifikationsnummer, Standortdaten, einen Online-Identifikator oder ein oder mehrere besondere Merkmale, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind. Dies umfasst zum Beispiel Ihren Namen, Ihre Telefonnummer, Ihre Adresse sowie alle Bestandsdaten, die Sie uns bei der Registrierung und Erstellung Ihres Kundenkontos zur Verfügung stellen. Statistische Daten oder anonymisierte Daten, die wir zum Beispiel bei Ihrem Besuch unseres Webshops erheben und die nicht direkt mit Ihrer Person in Verbindung gebracht werden können, sind hier nicht erfasst.",
      },
      purposeOfDataCollectionParagraph: {
        title: "Zwecke der Datenerhebung",
        para1:
          "a) Die Erhebung und Verarbeitung dieser Daten erfolgt zum Zweck der Vertragserfüllung, zur Ermöglichung der Nutzung unserer Website (Verbindungsaufbau), zur dauerhaften Gewährleistung der Systemsicherheit und -stabilität, zur Optimierung unserer Webpräsenz und zu internen statistischen Zwecken. Wir erfassen, speichern und verarbeiten personenbezogene Daten ausschließlich in Übereinstimmung mit den geltenden gesetzlichen Vorschriften und soweit dies erforderlich und zur Erfüllung und Verbesserung der vertraglichen Leistungspflichten zwischen uns und dem Nutzer / Kunden erforderlich ist. Dies beinhaltet auch die Speicherung von Daten für etwaige Gewährleistungsansprüche nach Beendigung des Vertrags.",
        para2:
          "b) Darüber hinaus nutzen wir Ihre Daten im Rahmen des anwendbaren Rechts für eigene Marketingzwecke, um unsere Webpräsenz zu optimieren und Ihnen auf Dienstleistungen aufmerksam zu machen, die für Sie von besonderem Interesse sein könnten.",
        para3:
          "c) Darüber hinaus erfolgt die Erhebung, Verarbeitung und Nutzung von personenbezogenen Daten nur, wenn Sie Ihre Einwilligung dazu erteilt haben.",
      },
      newsletterParagraph: {
        title: "Newsletter",
        para1:
          "Alquant informiert Sie gerne regelmäßig über aktuelle Themen und Projekte rund um unser Tätigkeitsfeld über den Newsletter. Sie erhalten von uns einen Newsletter nur auf ausdrücklichen Wunsch. Wir verwenden das Opt-In-Verfahren zum Versand des Newsletters, das bedeutet, dass Sie unseren Newsletter per E-Mail erhalten, sofern Sie zuvor ausdrücklich für den Newsletterversand angemeldet haben. Wenn Sie sich für den Newsletter angemeldet haben, wird Ihre E-Mail-Adresse zusammen mit Ihrer Einwilligung zu eigenen Werbezwecken verwendet, bis Sie sich vom Newsletter abmelden. Sie können sich jederzeit durch Senden einer E-Mail mit dem entsprechenden Hinweis an unsere Kontaktadresseinfo(at)alquant.com abmelden. Wir sind berechtigt, Dritte mit der technischen Abwicklung von Werbemaßnahmen zu beauftragen und dürfen Ihre Daten zu diesem Zweck weitergeben.",
        para2:
          "Die von Ihnen bei der Anmeldung zum Newsletter angegebenen Daten (Name und E-Mail-Adresse) werden gespeichert und ausschließlich zum Versand des Newsletters verwendet.",
      },
      legalBasisParagraph: {
        title: "Rechtsgrundlage für die Verarbeitung",
        para1:
          "Wir verarbeiten Ihre personenbezogenen Daten in erster Linie zur Erfüllung vertraglicher Verpflichtungen und unserer gesetzlichen Verpflichtungen. Darüber hinaus erklären Sie sich durch die Annahme dieser Datenschutzerklärung mit allen in dieser Datenschutzerklärung angegebenen Verarbeitungsvorgängen einverstanden. Selbstverständlich können Sie Ihre Einwilligung jederzeit widerrufen; eine Erklärung an compliance(at)alquant.com genügt. Eine bis zu diesem Zeitpunkt durchgeführte Verarbeitung wird durch Ihren Widerruf nicht berührt.",
      },
      legitimateInterestParagraph: {
        title: "Berechtigte Interessen für die Verarbeitung",
        para1:
          "Neben den zuvor genannten Rechtsgrundlagen für die Datenverarbeitung verarbeiten wir Daten auch auf Grundlage unserer berechtigten Interessen. Dies gilt insbesondere, aber nicht ausschließlich, für die Verarbeitung zu Marketing- und Werbezwecken. Wir verfolgen insbesondere die folgenden Interessen:",
        list1: {
          el1: "Sicherstellung der potenziellen Nutzung der Website",
          el2: "Betrugsprävention",
          el3: "Gewährleistung der Sicherheit und Stabilität der IT-Systeme",
          el4: "eigene Marketingzwecke",
          el5: "interne statistische Zwecke",
        },
      },
      disclosureParagraph: {
        title: "Offenlegung",
        para1:
          "Wir geben Ihre personenbezogenen Daten nur weiter, wenn Sie Ihre ausdrückliche Zustimmung erteilt haben, wenn es eine gesetzliche Verpflichtung dazu gibt oder wenn dies zur Durchsetzung unserer Rechte erforderlich ist - insbesondere zur Durchsetzung von Ansprüchen aus dem Vertragsverhältnis. Darüber hinaus geben wir Ihre Daten an Dritte weiter, soweit dies für die Vertragserfüllung erforderlich ist. Zu diesem Zweck legen wir die erforderlichen Daten Tochtergesellschaften, Transportunternehmen, Banken und anderen Dienstleistern offen. Diese Dienstleister verwenden Ihre Daten ausschließlich für die Auftragsabwicklung und nicht für andere Zwecke. Zusätzlich nutzen wir einige Dienste von externen Dienstleistern um Ihren Besuch auf unserer Website zu optimieren. Es wird (vertraglich) sichergestellt, dass diese Dienstleister Ihre Daten vertraulich und gemäß den geltenden gesetzlichen Bestimmungen behandeln und dass Ihre Daten nur für den Zweck des jeweiligen Dienstes verwendet werden. Eine Übertragung oder ein Weiterverkauf der Daten an Dritte durch Alquant über die Bestimmungen dieser Datenschutzerklärung hinaus ist ausgeschlossen, es sei denn, der Nutzer hat seine ausdrückliche Zustimmung zur Offenlegung seiner Daten gegeben. In diesem Fall kann der Nutzer diese Zustimmung jederzeit widerrufen. Aufgrund Ihrer Zustimmung bereits durchgeführte Verarbeitungsvorgänge werden nicht berührt.",
        para2:
          "Alquant ist berechtigt, Ihre personenbezogenen Daten an Dritte im Ausland weiterzugeben, wenn dies für die Vertragsdurchführung erforderlich ist. Diese Dritten sind an das gleiche Datenschutzniveau wie der Anbieter gebunden. Wenn das Datenschutzniveau in einem Land nicht dem schweizerischen Niveau entspricht, stellen wir vertraglich sicher, dass der Schutz Ihrer personenbezogenen Daten jederzeit demjenigen in der Schweiz entspricht.",
      },
      singleSignOnProcedureParagraph: {
        title: "Verwendung von Single Sign-On-Verfahren",
        para1:
          "Auf unserer Website können Sie sich mit einem Google- oder Microsoft-Profil anmelden, um ein Kundenkonto zu erstellen oder sich über das Social-Plugin mit der Single Sign-On-Technik zu registrieren. Die Plugins sind mit dem Logo des Anbieters gekennzeichnet.",
        para2:
          "Wenn Sie unsere Websites aufrufen, die ein solches Plugin enthalten, baut Ihr Browser eine direkte Verbindung mit den Computern des Anbieters auf. Der Inhalt des Plugins wird vom Anbieter direkt an Ihren Browser übertragen, der es in die Website integriert. Durch die Integration von Plugins erhält der Anbieter die Information, dass Sie unsere Website aufgerufen haben. Wenn Sie gleichzeitig beim Anbieter angemeldet sind, kann der Anbieter den Besuch Ihrem Profil zuordnen.",
        para3:
          "Wenn Sie nicht möchten, dass der Anbieter Daten über Sie über unsere Website sammelt, müssen Sie sich vor dem Besuch unserer Website aus dem Anbieter ausloggen. Auch wenn Sie ausgeloggt sind, sammeln Anbieter über Social-Plugins anonymisierte Daten und richten für Sie einen Cookie ein. Wenn Sie sich zu einem späteren Zeitpunkt beim Anbieter einloggen, können diese Daten Ihrem Profil zugeordnet werden.",
        para4:
          "Wenn ein Login über einen Social-Login-Dienst wie z. B. Google Connect bereitgestellt wird, werden Daten zwischen dem Anbieter und unserer Website ausgetauscht. Im Falle von Google Connect können das zum Beispiel Daten aus Ihrem öffentlichen Google-Profil sein. Durch die Nutzung solcher Login-Dienste stimmen Sie der Datenübertragung zu.",
        para5:
          "Bitte beachten Sie die Datenschutzhinweise des Anbieters bezüglich Zweck und Umfang der Datenerhebung und der weiteren Verarbeitung Ihrer Daten durch den Anbieter sowie Ihre jeweiligen Rechte und Einrichtungsoptionen zum Schutz Ihrer Privatsphäre.",
        para7:
          'Wenn Sie nicht möchten, dass der Anbieter Daten über Sie über diese Cookies sammelt, können Sie in Ihren Browsereinstellungen die Funktion "Cookies von Drittanbietern blockieren" auswählen. Wenn dann eingebettete Inhalte von anderen Anbietern vorhanden sind, sendet der Browser keine Cookies an den Server.',
      },
      durationOfRetentionParagraph: {
        title: "Aufbewahrungsdauer",
        para1:
          "Wir speichern Ihre persönlichen Daten so lange, wie es zur Erfüllung geltender Gesetze erforderlich ist oder wir es für angemessen halten oder solange es für die Zwecke, für die es erhoben wurde, erforderlich ist. Wir sperren oder löschen Ihre persönlichen Daten, sobald sie nicht mehr benötigt werden und definitiv beim Ablauf der gesetzlichen Höchstspeicherfrist.",
        para2:
          "Sie können jederzeit eine Löschung Ihrer persönlichen Daten beantragen, indem Sie uns eine entsprechende Anfrage an compliance(at)alquant.com senden. Wir werden Ihrer Anfrage umgehend nachkommen, sofern wir nicht aus anderen Gründen verpflichtet sind, sie aufzubewahren oder sie benötigen, z. B. für die Durchsetzung von Ansprüchen.",
      },
      furtherDataSourcesParagraph: {
        title: "Weitere Datenquellen",
        para1:
          "Neben den Daten, die wir direkt von Ihnen sammeln, beziehen wir auch Daten aus öffentlichen Registern (wie dem Handelsregister) und von Drittanbietern.",
        para2:
          "Da sich die Datenquellen je nach Kunde unterscheiden, kann keine allgemeine Information zu diesen Quellen gegeben werden. Wenn gewünscht, sind wir selbstverständlich bereit, Ihnen unsere Datenquellen im Detail offenzulegen.",
      },
      userRightsParagraph: {
        title: "Nutzerrechte",
        rightRevocationSub: {
          title: "Recht auf Widerruf",
          para1:
            "Sofern Sie uns Ihre Einwilligung zur Erhebung, Speicherung und Verwendung personenbezogener Daten im Hinblick auf die Website oder unsere Dienstleistungen gegeben haben, können Sie diese Einwilligung jederzeit widerrufen. Der Widerruf kann per E-Mail oder schriftlich an den oben in Abschnitt 2 genannten Kontakt an die verantwortliche Stelle gesendet werden. Die Auswirkungen des Widerrufs beschränken sich auf die Speicherung und Verwendung personenbezogener Daten, die ohne Ihre vorherige Einwilligung aufgrund gesetzlicher Erlaubnis nicht gespeichert und verwendet werden dürfen. Der Widerruf hat auch keine Auswirkungen auf frühere Verarbeitungsvorgänge auf der Grundlage der Einwilligung.",
        },
        furtherRightsSub: {
          title: "Weitere Rechte",
          informationSub: {
            title: "Auskunft",
            para1:
              "Auf schriftliche Anfrage geben wir Ihnen jederzeit Auskunft über Ihre gespeicherten personenbezogenen Daten. Die Anfrage auf Auskunft muss schriftlich und mit Identitätsnachweis (Kopie von Personalausweis, Reisepass oder anderem amtlichen Identitätsnachweis) gestellt werden. Die Ausübung dieses Rechts ist einmal im Jahr kostenlos; für weitere Anfragen behalten wir uns vor, Kosten in Rechnung zu stellen.",
          },
          correctionSub: {
            title: "Berichtigung, Löschung, Einschränkung der Verarbeitung",
            para1:
              "Darüber hinaus haben Sie jederzeit die Möglichkeit, Ihre personenbezogenen Daten von uns korrigieren, sperren oder löschen zu lassen. Wenden Sie sich bitte an die interne Behörde, deren Einzelheiten in Abschnitt 2 zu finden sind. Ausgenommen von der Löschung sind ausschließlich Daten, die wir zur Erledigung noch ausstehender Aufgaben oder zur Durchsetzung bestehender Rechte und Ansprüche benötigen sowie Daten, die wir aufgrund gesetzlicher Vorschriften aufbewahren müssen. Diese Daten werden gesperrt.",
          },
          rightAppealSub: {
            title: "Widerspruchsrecht",
            para1:
              "Wir legen großen Wert auf die Wahrung der Rechte unserer Kunden und damit auch auf den Schutz Ihrer personenbezogenen Daten. Sollten Sie jedoch der Ansicht sein, dass wir Ihre Daten unrechtmäßig verarbeiten, können Sie uns jederzeit unter +41 41 661 23 45 oder per E-Mail an compliance(at)alquant.com mit Details Ihres Anliegens kontaktieren. Wir werden uns nach bestem Wissen und Gewissen um Ihre Anliegen kümmern.",
            para2:
              "Wenn unser Kundenservice Ihnen entgegen Ihrer Erwartungen keine zufriedenstellende Hilfe leisten kann, können Sie Ihr Anliegen jederzeit als formale Beschwerde beim Eidgenössischen Datenschutz- und Öffentlichkeitsbeauftragten einreichen, das dann untersucht wird.",
          },
          dataPortabilitySub: {
            title: "Datenübertragbarkeit",
            para1:
              "Auf schriftlichen Antrag haben Sie auch das Recht, personenbezogene Daten, die Sie betreffen, in einem strukturierten, gängigen und maschinenlesbaren Format (PDF-Datei) zu erhalten, um sie an einen anderen Verantwortlichen übermitteln zu lassen. Wir behalten uns das Recht vor, dafür anfallende Kosten zu berechnen.",
          },
        },
      },
      cookiesParagraph: {
        title: "Verwendung von Cookies",
        para1:
          "Zur Verbesserung unseres Angebots verwenden wir Cookies auf unserer Website. Ein Cookie ist eine kleine Textdatei, die auf Ihren Computer übertragen wird, wenn Sie unsere Seiten durchsuchen. Ein Cookie kann nur Informationen enthalten, die wir an Ihr elektronisches Gerät senden - sie können nicht verwendet werden, um personenbezogene Daten zu lesen. Wenn Sie Cookies auf unserer Website akzeptieren, haben wir keinen Zugriff auf Ihre persönlichen Informationen, aber mit Hilfe von Cookies können wir Ihren Computer identifizieren. In den verwendeten Cookies sind keine personenbezogenen Daten gespeichert, sodass wir keine Rückschlüsse auf Ihre Identität durch die Cookie-Technologie ziehen können. Es werden nur pseudonymisierte Informationen generiert.",
        para2:
          "Wir verwenden Cookies, um zu überprüfen, ob Sie unseren Nutzungsbedingungen entsprechen.",
        para3:
          "Wir verwenden sitzungsbezogene, temporäre Cookies: diese bleiben nicht auf Ihrem Computer. Wenn Sie unsere Seiten verlassen, wird auch das temporäre Cookie verworfen. Mit Hilfe der gesammelten Informationen können wir die Nutzungsmuster und Strukturen unserer Website analysieren und durch Verbesserung des Inhalts oder der Personalisierung und Vereinfachung der Nutzung optimieren.",
        para4:
          "Darüber hinaus verwenden wir persistente Cookies: diese bleiben auf Ihrem Computer, um Einkaufs-, Personalisierungs- und Registrierungsdienste bei Ihrem nächsten Besuch zu vereinfachen. Diese Cookies können beispielsweise aufzeichnen, was Sie beim Einkaufen ausgewählt haben, während Sie weiter einkaufen. Außerdem müssen Sie Ihr Passwort nur einmal auf Webseiten eingeben, die eine Anmeldung erfordern. Persistente Cookies können vom Benutzer manuell entfernt werden. Sie löschen sich nach Ablauf einer bestimmten Zeit selbst.",
        para5:
          "Einige unserer Werbepartner verwenden ebenfalls Cookies (Third-Party-Cookies). Diese Cookies helfen uns, unsere Website an die spezifischen Bedürfnisse unserer Kunden anzupassen, indem sie beispielsweise Anzeigen anzeigen, die Sie tatsächlich interessieren könnten. Mit Hilfe dieser Cookies können wir Informationen darüber sammeln, welche Produkte Sie angesehen oder nach welchen Produkten Sie gesucht haben oder welche Seiten Sie zuvor besucht haben. Diese Cookies werden auf Ihrer Festplatte gespeichert und löschen sich nach Ablauf einer bestimmten Zeit selbst. Es werden auch keine persönlichen Informationen in den Cookies unserer Werbepartner gespeichert. Die Verwendung erfolgt pseudonymisiert, so dass kein Rückschluss auf Ihre Identität gezogen wird.",
        para6:
          "Die meisten Browser (wie Firefox, Chrome, Internet Explorer, Safari usw.) akzeptieren Cookies standardmäßig. Sie können temporäre und persistente Cookies in den Sicherheitseinstellungen unabhängig voneinander zulassen oder blockieren. Wir möchten jedoch darauf hinweisen, dass bestimmte Funktionen auf unserer Website nicht verfügbar sind und einige Websites möglicherweise nicht ordnungsgemäß angezeigt werden, wenn Sie Cookies deaktivieren.",
        para7:
          "Die in unseren Cookies gespeicherten Daten werden ohne Ihre ausdrückliche Zustimmung nicht mit Ihren persönlichen Daten (Name, Adresse usw.) verknüpft.",
      },
      liabilityForLinksParagraph: {
        title: "Haftung für Links",
        para1:
          "Alquant erklärt ausdrücklich, dass zum Zeitpunkt der Linksetzung keine illegalen Inhalte auf den zu verlinkenden Seiten erkennbar waren. Wir haben keinen Einfluss auf die aktuelle und zukünftige Gestaltung, den Inhalt oder die Urheberschaft der verlinkten Seiten. Deshalb distanzieren wir uns hiermit ausdrücklich von allen Inhalten aller verlinkten Seiten, die nach der Linksetzung verändert wurden. Diese Erklärung gilt für alle auf unserer Webpräsenz angebrachten Links und Verweise. Bezüglich rechtswidriger, fehlerhafter oder unvollständiger Inhalte und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung solcherart dargebotener Informationen entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen wurde, nicht derjenige, der lediglich auf die jeweilige Veröffentlichung verweist.",
      },
      webAnalysisParagraph: {
        title: "Webanalyse",
        googleAnalyticsSub: {
          title: "Google Analytics",
          para1:
            "Diese Website verwendet Google Analytics, einen Webanalysedienst von Google Inc. (Google) zur bedarfsgerechten Gestaltung von Websites. Wir erhalten nur Datenbewertungen ohne persönliche Bezüge.",
          para2:
            "Google Analytics verwendet Cookies: Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse Ihrer Nutzung der Website ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Nutzung dieser Website (einschließlich Ihrer IP-Adresse) werden an einen Server von Google in den USA übertragen und dort gespeichert. Auf dieser Website wurde die IP-Anonymisierung aktiviert, so dass die IP-Adresse von Google-Nutzern innerhalb der Mitgliedsstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vor der Übertragung gekürzt wird. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Google-Server in den USA übertragen und dort gekürzt. Google wird diese Informationen im Auftrag des Betreibers dieser Website verwenden, um die Nutzung der Website durch die Nutzer auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Website- und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Google wird diese Informationen auch an Dritte übertragen, sofern dies gesetzlich vorgeschrieben ist oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Google wird Ihre IP-Adresse niemals mit anderen Google-Daten in Verbindung bringen.",
          para3:
            "Sie können die Installation von Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern. Wir weisen jedoch darauf hin, dass Sie in diesem Fall möglicherweise nicht alle Funktionen dieser Website vollständig nutzen können. Durch die Nutzung dieser Website erklären Sie sich mit der Verarbeitung der über Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck einverstanden.",
          para4:
            'Darüber hinaus können Sie die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (einschließlich Ihrer IP-Adresse) sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter <a href="https://tools.google.com/dlpage/gaoptout" >https://tools.google.com/dlpage/gaoptout</a > verfügbare Browser-Plugin herunterladen und installieren. Weitere Informationen zu den Nutzungsbedingungen und zum Datenschutz von Google Analytics finden Sie unter',
          para5:
            '<a href="https://www.google.com/analytics/terms/" >https://www.google.com/analytics/terms/</a > und',
          para6:
            '<a href="https://policies.google.com/privacy?hl=en" >https://policies.google.com/privacy?hl=en</a >',
        },
        googleFontsSub: {
          title: "Google-Schriftarten",
          para1:
            'Diese Website verwendet auch Google-Schriftarten (Open Scans). Aus technischen Gründen muss Ihre IP-Adresse an Google übertragen werden, damit die Schriftarten an Ihren Browser übertragen werden können. Weitere Informationen zu Google-Schriftarten finden Sie unter <a class="break-all" href="https://developers.google.com/fonts/faq#what_does_using_the_google_fonts_api_mean_for_the_privacy_of_my_users" >https://developers.google.com/fonts/faq#what_does_using_the_google_fonts_api_mean_for_the_privacy_of_my_users</a>.',
        },
      },
      securityMeasureParagraph: {
        title: "Sicherheitsmaßnahmen",
        para1:
          "Wir wenden technische und organisatorische Sicherheitsmaßnahmen an, um Ihre persönlichen Informationen vor unbefugtem Zugriff, Missbrauch, unbeabsichtigter oder vorsätzlicher Manipulation, Verlust oder Zerstörung angemessen zu schützen. Zum Schutz Ihrer Privatsphäre verwenden wir eine sichere Verbindung für unsere Website, die Sie anhand von „https“ (https steht für Hypertext Transfer Protocol Secure) am Anfang der Adressleiste in Ihrem Browser erkennen können. Auf diese Weise wird die Verbindung über SSL/TSL verschlüsselt und Ihre Daten können nicht von Dritten abgefangen werden. Technische Sicherheitsmaßnahmen zum Schutz Ihrer persönlichen Daten werden regelmäßig überprüft und bei Bedarf aktualisiert. Diese Grundsätze gelten auch für Unternehmen, die im Auftrag und gemäß unseren Anweisungen personenbezogene Daten verarbeiten und nutzen.",
      },
      legalBasisGeneralDataProtectionParagraph: {
        title: "Rechtsgrundlage der Datenschutz-Grundverordnung",
        para1:
          "Gemäß den Bestimmungen der Datenschutz-Grundverordnung (DSGVO), die ab dem 25. Mai 2018 gilt, informieren wir Sie darüber, dass Ihre Zustimmung zur Zusendung an die von Ihnen angegebene E-Mail-Adresse auf der Grundlage von Art. 6 Abs. 1 lit. a und Art. 7 DSGVO erteilt wird. Die entsprechende Versandliste wird auf der Grundlage unserer berechtigten Interessen gemäß Art. 6 Abs. 1 lit. f DSGVO verwendet. Wir sind daran interessiert, eine benutzerfreundliche und sichere Website und System zu nutzen, die unseren Geschäftsinteressen dient, aber auch den Erwartungen unserer Benutzer und Kunden entspricht.",
      },
      changesParagraph: {
        title: "Änderungen",
        para1:
          "Wir behalten uns das Recht vor, diese Datenschutzrichtlinie jederzeit zu ändern. Die Datenschutzrichtlinie stellt keine vertragliche oder andere formelle rechtliche Beziehung mit oder im Namen einer Partei dar.",
      },
    },
    termsOfUse: {
      endDate: "Crissier, 3. Februar 2022",
      title: "Nutzungsbedingungen",
      metaInfo: { title: "Plattform - Nutzungsbedingungen" },
      declarationOfAcceptanceParagraph: {
        title: "Erklärung der Annahme durch den Benutzer",
        para1:
          'Diese Website wird von Alquant SA betrieben. Durch die Nutzung dieser Website (oder einer Seite, die auf diese Webadresse umgeleitet wurde; im Folgenden in toto als "Alquant-Website" bezeichnet) bestätigen Sie, dass Sie die folgenden Nutzungsbedingungen einschließlich der rechtlichen Bedingungen gelesen, verstanden und akzeptiert haben. Wenn Sie diese Nutzungsbedingungen nicht akzeptieren, besuchen Sie die Alquant-Website bitte nicht erneut.',
      },
      limitedAccessParagraph: {
        title: "Eingeschränkter Zugang",
        para1:
          "Die Alquant-Website ist nicht für juristische und natürliche Personen bestimmt, die aus Gründen der Staatsangehörigkeit, des Geschäftssitzes, des Wohnsitzes oder aus anderen Gründen einem Rechtssystem unterliegen, das insbesondere Folgendes verbietet oder beschränkt:",
        list1: {
          el1: "die Veröffentlichung der Inhalte,",
          el2: "den Zugang zur Alquant-Website,",
          el3: "die Aktivitäten eines ausländischen Finanzdienstleisters,",
          el4: "die Zulassung von Finanzprodukten (z. B. Zertifikaten oder Fonds).",
        },
        para3:
          "Außerdem ist sie nicht für die Verwendung durch juristische oder natürliche Personen in Ländern vorgesehen, in denen die Alquant SA oder mit ihr verbundene Unternehmen nicht über die erforderliche Registrierung und Genehmigung zur Durchführung von Geschäften verfügen.",
        para4:
          "Personen, die einem oder allen dieser Einschränkungen unterliegen, dürfen nicht auf die Alquant-Website zugreifen. Dies gilt insbesondere für Bürger der USA, des Vereinigten Königreichs, Kanadas, Japans, Chinas und Singapurs oder Personen, die in den genannten Ländern ansässig sind. Bitte informieren Sie sich über die entsprechenden rechtlichen Beschränkungen in Ihrem Land.",
        para5: "Hinweis für institutionelle Anleger",
        para6:
          'Finanzprodukte für professionelle Anleger sind ausschließlich für qualifizierte Anleger erhältlich. Gemäß Art. 10 Abs. 3 des Kollektivanlagengesetzes zusammen mit Art. 6 der Kollektivanlagenverordnung umfassen qualifizierte Anleger institutionelle Anleger mit einem professionellen Treasury, nämlich Banken und Wertpapierhändler (einschließlich ihrer Kunden, die einen schriftlichen Vermögensverwaltungsvertrag haben), Pensionsfonds, Versicherungsgesellschaften, Fondsmanager und lokale und kantonale Behörden sowie Industrie- und Handelsunternehmen. Durch Auswahl von "Professionelle Anleger" bestätigen Sie, dass Sie gemäß der oben genannten Definition ein qualifizierter Anleger sind.',
      },
      propertyRightsParagraph: {
        title: "Eigentumsrechte",
        para1:
          "Alle Elemente auf der Alquant-Website sind als immaterielles Eigentum geschützt und gehören Alquant SA oder Dritten. Die Nutzung der Alquant-Website beinhaltet keine Übertragung von Rechten, insbesondere keine Rechte in Bezug auf Software, Markennamen oder Elemente der Alquant-Website. Urheberrechtsvermerke und Markennamen dürfen nicht geändert oder entfernt werden. Elemente der Alquant-Website dürfen nicht vollständig oder teilweise ohne Verweis auf die vollständige Quelle reproduziert werden. Der Inhalt der Alquant-Website darf ohne vorherige schriftliche Zustimmung von Alquant SA nicht für öffentliche oder kommerzielle Zwecke reproduziert oder genutzt werden.",
      },
      consultingReplacementParagraph: {
        title: "Kein Ersatz für Beratung",
        para1:
          "Die auf der Alquant-Website veröffentlichten Informationen und Meinungen haben einen allgemeinen Charakter. Sie sind weder dazu bestimmt noch geeignet, die individuelle Beratung durch Fachpersonal zu ersetzen, das die tatsächlichen Umstände in einem bestimmten Einzelfall berücksichtigt. Aus diesem Grund stellen sie weder eine rechtliche, steuerliche, finanzielle, wirtschaftliche oder sonstige Beratung dar, noch dürfen sie als Investitionshilfe genutzt werden.",
      },
      noOffersParagraph: {
        title: "Keine Angebote",
        para1:
          "Die auf der Alquant-Website veröffentlichten Informationen und Meinungen stellen weder eine Werbung noch Empfehlungen dar und sollten nicht als Aufforderung, ein Angebot abzugeben, ausgelegt werden:",
        list1: {
          el1: "zum Erwerb oder Verkauf von Anlageinstrumenten,",
          el2: "für die Durchführung von Transaktionen jeglicher Art,",
          el3: "für die Durchführung jeglicher anderer rechtlicher Geschäfte.",
        },
        para3:
          "Der einzige Zweck der Alquant-Website besteht darin, Informationen bereitzustellen.",
      },

      futurePerformanceParagraph: {
        title: "Zukünftige Performance (Risikohinweis)",
        para1:
          "Die zukünftige Performance eines Investmentinstruments kann nicht aus vergangenen Kursbewegungen abgeleitet werden, in anderen Worten, das Wertpapier kann steigen oder fallen. Wie sich das Wertpapier in der Vergangenheit verhalten hat, ist daher keine Garantie für seinen zukünftigen Wert. Im Falle bestimmter Investmentinstrumente ist auch der Verlust des gesamten investierten Kapitals in extremen Fällen möglich. Darüber hinaus können Schwankungen der Wechselkurse auch die Investition beeinflussen.",
      },
      noGuaranteeParagraph: {
        title: "Keine Garantie",
        para1:
          "Alquant SA bietet keine Garantien (einschließlich Haftung gegenüber Dritten) oder Zusicherungen für",
        list1: {
          el1: "die Aktualität, Präzision, Zuverlässigkeit oder Vollständigkeit der Inhalte der Alquant-Website,",
          el2: "die Ergebnisse, die durch die Nutzung der Alquant-Website erzielt werden,",
          el3: "die Inhalte von Dritten, die auf der Alquant-Website gefunden oder zugänglich gemacht werden können.",
        },
        para3: "Darüber hinaus haftet Alquant SA nicht und garantiert nicht dafür, dass",
        list2: {
          el1: "die Funktionen auf der Alquant-Website ohne Fehler funktionieren und ohne Unterbrechung zur Verfügung stehen,",
          el2: "die Alquant-Website und andere über den Zugang zur Alquant-Website genutzte Komponenten frei von Viren usw. sind.",
        },
        para5: "Alquant SA bietet auch keine Garantie für",
        list3: {
          el1: "die Wertsteigerung oder Stabilität des in Investmentinstrumente investierten Kapitals,",
          el2: "das Niveau zukünftiger Dividenden.",
        },
      },
      linksParagraph: {
        title: "Links",
        para1:
          "Alquant SA hat die von Dritten mit der Alquant-Website verlinkten Websites nicht überprüft und kann nicht für die Aktualität, Präzision, Zuverlässigkeit, Vollständigkeit oder Rechtmäßigkeit der Inhalte und Angebote auf solchen Websites haftbar gemacht werden. Der Nutzer greift daher auf solche Webseiten auf eigene Gefahr zu.",
        para2:
          "Links zu Webseiten außerhalb der Alquant-Website dürfen nur mit der vorherigen Zustimmung von Alquant SA eingefügt werden.",
      },
      serviceChangesParagraph: {
        title: "Änderungen des Dienstes",
        para1:
          "Alquant SA kann jederzeit jeden Aspekt der Dienste ändern, aussetzen oder einstellen, einschließlich der Verfügbarkeit von Funktionen, Datenbanken oder Inhalten. Alquant SA kann auch Grenzen für bestimmte Funktionen und Dienste festlegen oder den Zugang des Benutzers zu allen oder Teilen der Dienste ohne Vorankündigung einschränken.",
        para2:
          "Alquant SA behält sich das Recht vor, den Zugang des Benutzers zu den Diensten jederzeit nach eigenem Ermessen und ohne Vorankündigung zu beenden.",
      },
      useOfInformationParagraph: {
        title: "Verwendung von Informationen",
        para1:
          "Der Benutzer darf die auf der Alquant-Website veröffentlichten Informationen nur für interne Geschäftszwecke oder persönliche, nichtkommerzielle Zwecke verwenden. Der Benutzer darf keine Alquant SA-Informationen in irgendeinem Format veröffentlichen, verkaufen, vermieten, verbreiten, weiterleiten, neu verteilen, senden oder anderweitig bereitstellen oder einem Dritten den Zugang dazu gestatten. Der Benutzer kann jedoch Informationen an Benutzer weitergeben, und zwar nur auf nicht kontinuierlicher Basis, und zwar nur auf folgende begrenzte Weise: (a) In schriftlichen Anzeigen, Korrespondenz oder anderen Literatur oder (b) während von Hand geführter Telefongespräche, die keine computergestützten Sprach-, automatisierten Informationsabfragesysteme oder ähnliche Technologien beinhalten.",
        para2:
          "Die Verwendung von automatischen Skripting-Tools zum Crawlen der Website oder der Versuch, die API zu umgehen, ist strengstens untersagt und kann dazu führen, dass der Zugang des Benutzers zur Alquant-Website blockiert wird. Übermäßige Anfragen können zu einer Drosselung des Zugangs Ihres Kontos zu Daten führen.",
        para3:
          "Die Verwendung von nicht-API-basierten Formen der Automatisierung, wie das Scripting der Website, ist verboten. Die Verwendung dieser Techniken kann zur dauerhaften Sperrung Ihres Kontos führen.",
      },
      indemnificationParagraph: {
        title: "Entschädigung",
        para1:
          "Der Benutzer (und der Fonds des Benutzers, wenn der Benutzer ein Vertreter eines Fonds ist) erklärt sich gemeinsam und mehrfach damit einverstanden, Alquant SA und seine Agenten, Informationsanbieter und deren Agenten, Vertreter, Direktoren, Offiziere, Aktionäre, Partner, Vorgänger, Nachfolger und Abtretungsempfänger von und gegen jegliche Ansprüche oder Forderungen, Verfahren, Schäden, Haftungen, Verluste oder Ausgaben, die durch Ansprüche Dritter entstehen, von der Verwendung der Alquant SA-Informationen durch den Benutzer, die einen Verstoß gegen die Nutzungsbedingungen oder eine unangemessene Handlung oder Unterlassung darstellt, freizustellen und schadlos zu halten.",
        para2:
          "Wenn ein Benutzer die Dienste oder Inhalte nicht gemäß diesen Nutzungsbedingungen ausdrücklich genehmigt nutzt, kann er das Urheberrecht und andere Gesetze verletzen und Strafen unterliegen. Ohne die vorhergehenden Sätze einzuschränken, wird der Benutzer nicht:",
        para3:
          "Den Weiterverkauf, die Anzeige, Vervielfältigung, Kopie, Übersetzung, Änderung, Anpassung, Erstellung von abgeleiteten Werken, Verbreitung, Übertragung, Übermittlung, Republikation, Kompilierung, Dekompilierung oder Reverse Engineering der Dienste, Inhalte, eines Teils davon oder von Daten, anderen Inhalten oder Informationen, die dem Benutzer im Zusammenhang mit der Nutzung der Dienste durch Alquant SA oder einen ihrer Drittanbieterquellen zur Verfügung gestellt werden, in jeder Form, Medien oder Technologie, die jetzt existiert oder in Zukunft entwickelt wird, oder über jedes Netzwerk, einschließlich eines lokalen Netzwerks, gestatten oder selbst durchführen; Entfernen, Verbergen oder Ändern von Hinweisen, Haftungsausschlüssen oder anderen Offenlegungen, die den Diensten beigefügt oder in ihnen enthalten sind, einschließlich aller Urheberrechtshinweise, Marken oder anderer Hinweise auf Eigentumsrechte oder irgendwelche anderen rechtlichen Hinweise, die mit den Inhalten in Verbindung gebracht werden; Ein Frame erstellen oder Framing-Techniken verwenden, um einen Teil der Dienste oder Inhalte ohne ausdrückliche vorherige schriftliche Zustimmung von Alquant SA einzuschließen;",
        para4:
          "Sich als eine Person ausgeben oder falsch angeben oder anderweitig seine Zugehörigkeit zu einer Person in Zusammenhang mit der Nutzung der Dienste darstellen; Einbrechen oder versuchen, die Sicherheit der Dienste, eines Netzwerks, Servers, Daten oder Computers oder anderer Hardware in Bezug auf oder in Verbindung mit den Diensten oder einem Teil der Inhalte zu verletzen; keine Software oder anderen Werkzeuge oder Geräte verwenden oder durch die Dienste verbreiten, die dazu bestimmt sind, die Privatsphäre, Sicherheit oder Nutzung der Dienste durch andere oder die Operationen oder Vermögenswerte einer Person zu beeinträchtigen oder zu gefährden;",
        para5:
          "Geltende Gesetze verletzen, einschließlich, aber nicht beschränkt auf Wertpapiergesetze und Gesetze, die die Investitionstätigkeit in einem Land regeln; Einen anderen autorisierten Benutzer daran hindern, die Dienste zu nutzen und zu genießen; Keinen der Inhalte oder andere Informationen oder Materialien, die durch die Dienste erhalten wurden, zu kommerziellen Zwecken posten, veröffentlichen, übertragen, reproduzieren, verteilen oder auf irgendeine Weise ausnutzen, es sei denn, dies ist ausdrücklich durch diese Nutzungsbedingungen oder durch ausdrückliche vorherige Genehmigung von Alquant SA gestattet.",
      },
      exclusionLiabilityParagraph: {
        title: "Haftungsausschluss",
        para1:
          "Alquant SA oder jede damit verbundene Unternehmen, Partner, Vorstände, Mitarbeiter oder Vertreter lehnen jegliche Haftung (einschließlich Fahrlässigkeit und gegenüber Dritten) für direkte oder indirekte Schäden oder Folgeschäden ab, die in direktem oder indirektem Zusammenhang mit der Nutzung der Alquant-Website entstehen können.",
      },
      reservationsParagraph: {
        title: "Vorbehalte",
        para1:
          "Alquant SA behält sich das Recht vor, jederzeit ohne vorherige Ankündigung an die Benutzer Informationen, Meinungen, Leistungen oder andere Daten, Links usw. zu ändern.",
        para2:
          "Darüber hinaus können auch die Nutzungsbedingungen geändert und die Nutzung der Alquant-Website ohne vorherige Ankündigung eingeschränkt oder beendet werden.",
      },
      placeOfJurisdictionParagraph: {
        title: "Gerichtsstand",
        para1:
          "Soweit zwischen Alquant SA und den Benutzern der Alquant-Website eine Rechtsposition entsteht, unterliegt diese schweizerischem Recht. Der alleinige Gerichtsstand ist Lausanne, Schweiz.",
      },
    },
  },
  errors: {
    backHome: "Zurück zur Startseite",
    error403: {
      paragraphWhenLoggedIn:
        "Sie haben keine Berechtigung, auf diese Seite zuzugreifen. Wenn Ihr Profil akzeptiert wird, wird Ihnen ein Administrator in Kürze die Rechte für den Zugriff auf diese Seite erteilen. Bitte schauen Sie in den nächsten 1-2 Werktagen wieder vorbei.",
      paragraphWhenLoggedInWithContact: (ctx) =>
        `Sie haben keine Berechtigung, auf diese Seite zuzugreifen. Wenn Ihr Profil akzeptiert wird, wird Ihnen ein Administrator in Kürze die Rechte für den Zugriff auf diese Seite erteilen. Bitte schauen Sie in den nächsten 1-2 Werktagen wieder vorbei. Wenn Sie dann immer noch keinen Zugang erhalten haben, kontaktieren Sie uns bitte: ${ctx.named(
          "contactEmail"
        )}.`,
      paragraphWhenNotLoggedIn:
        "Für den Zugriff auf diese Seite müssen Sie authentifiziert sein. Bitte klicken Sie auf die Schaltfläche unten, um sich bei Ihrem Konto anzumelden. Wenn Sie noch kein Konto haben, registrieren Sie sich bitte, indem Sie auf die Schaltfläche unten klicken.",
      title: "403 - Verbotener Zugang",
      metaInfo: { title: "Plattform - 403" },
    },
    error404: {
      paragraph: "Die von Ihnen versuchte Seite existiert nicht.",
      title: "404 - Seite nicht gefunden",
      metaInfo: { title: "Plattform - 404" },
    },
    unauthorizedInvestorProfile: {
      modifyInvestorProfile: "Investorenprofil ändern",
      title: "Nicht autorisiertes Investorenprofil",
      unfortunately:
        "Leider haben Sie aufgrund Ihres Investorenprofils keinen Zugriff auf diese Seite.",
      metaInfo: { title: "Plattform - Nicht autorisiertes Investorenprofil" },
    },
  },
  help: {
    contact: {
      address: "Adresse",
      introText:
        "Zögern Sie nicht, dieses Formular zu verwenden, um mit uns in Kontakt zu treten. Wir werden uns freuen, alle Vorschläge zu bearbeiten oder alle Arten von Fragen zu beantworten, die Sie im Zusammenhang mit dieser Plattform und unseren Produkten haben könnten.",
      recaptchaPrivacyPolicy: "Datenschutzerklärung",
      recaptchaProtect:
        "Diese Seite ist durch reCAPTCHA und die Google {0} geschützt, wobei die {1} gelten.",
      recaptchaTermsOfService: "Nutzungsbedingungen",
      switzerland: "Schweiz",
      title: "Kontakt",
      dialog: {
        confirmMessage: {
          title: "Bestätigen",
          text: "Möchten Sie diese Nachricht senden?",
        },
      },
      form: {
        country: "Land",
        email: "E-Mail",
        enterMessage: "Geben Sie Ihre Nachricht ein",
        exampleEmail: "max.mustermann@example.com",
        exampleName: "Max Mustermann",
        investorProfileType: "Investorentyp",
        name: "Name",
        sendEmail: "E-Mail senden",
      },
      metaInfo: { title: "Plattform - Kontakt" },
      notify: {
        errorMessage: {
          title: "Fehler beim Senden der Nachricht",
          text: "Sorry, die Nachricht konnte nicht gesendet werden. Versuchen Sie es später erneut oder kontaktieren Sie uns direkt per E-Mail.",
        },
        messageSent: {
          title: "Nachricht gesendet",
          text: "Die Nachricht wurde erfolgreich gesendet. Wir werden uns innerhalb von 1-2 Werktagen bei Ihnen melden.",
        },
      },
    },
    faq: {
      subtitle: "Schauen Sie sich die folgende Liste für alle häufig gestellten Fragen an.",
      title: "Haben Sie Fragen?",
      metaInfo: { title: "Plattform - FAQ" },
    },
    knowledgeBase: {
      investmentStrategyTechniques: "Anlagestrategietechniken",
      metaInfoTerm: (ctx) => `Wissensdatenbank - ${ctx.named("term")}`,
      noResults: "Keine Ergebnisse gefunden.",
      performanceMetrics: "Performance-Kennzahlen",
      search: "Suche",
      source: "Quelle",
      subtitle:
        "Finden Sie Erklärungen zu allgemeinen Fachbegriffen sowie zu einigen unserer auf dieser Plattform dargestellten Techniken.",
      title: "Willkommen in unserer Wissensdatenbank",
      alpha: {
        title: "Alpha",
        para1:
          "{0} wird verwendet, um die überlegene Performance einer Strategie oder ihres Managers im Vergleich zu einem Benchmark zu messen. Das {1} beschreibt die Performance nach Abzug des risikofreien Zinssatzes und der marktbezogenen Rendite (Marktprämie * {2}).",
        para2:
          'Das obige Diagramm zeigt einen <span class="font-bold">Alpha</span>-Wert von 4%, was bedeutet, dass die Strategie bei der aktuellen Zinsentwicklung (risikofreie Rendite) und ihrer Marktabhängigkeit eine zusätzliche Performance von 4% im Vergleich zu dem erwarteten Ergebnis erzielen konnte.',
        para3:
          'Wie im obigen Diagramm angegeben, ist die <span class="font-bold">Alpha</span>-Komponente der einzige Performance-Treiber, der dem Können des Managers zugeschrieben werden kann. Es zeigt auch, dass <span class="font-bold">Alpha</span> eine negative Zahl werden kann, was bedeutet, dass durch das aktive Management der Investition Performance verloren gegangen ist. Ein positiver <span class="font-bold">Alpha</span>-Wert kann jedoch als die Komponente angesehen werden, für die ein Anleger eine Gebühr zahlen möchte.',
        para4:
          "Je höher das Alpha, desto besser die Strategie. Ein hohes Alpha bedeutet, dass die Vermögensverwalter es geschafft haben, Wert zu schaffen.",
      },
      annualizedReturn: {
        title: "Annualisierte Rendite",
        para1:
          '<span class="font-bold">Annualisierte Renditen</span> sind kumulative Renditen, die auf einen Zeitraum von einem Jahr umgerechnet wurden. Dies ermöglicht es Anlegern, Renditen verschiedener Vermögenswerte, die sie für unterschiedliche Zeiträume gehalten haben, besser zu vergleichen.',
        para2:
          'Alternativ können <span class="font-bold">annualisierte Renditen</span> auch als die einjährige Rendite interpretiert werden, die ein Anleger im Durchschnitt erzielt hätte, indem er während eines bestimmten Zeitraums investiert hätte.',
        para3:
          "Je höher die annualisierte Rendite, desto besser ist die Investition, wenn alle anderen Faktoren gleich bleiben.",
      },
      annualizedVolatility: {
        title: "Annualisierte Volatilität",
        para1:
          '<span class="font-bold">Annualisierte Volatilität</span> ist die Volatilität einer Anlage, die auf einen Zeitraum von einem Jahr skaliert wird. Die <span class="font-bold" >Volatilität</span> selbst misst die Größenordnung von Preisbewegungen. Technischer ausgedrückt ist <span class="font-bold" >Volatilität</span> ein statistisches Maß für die Streuung von Renditen (insbesondere die Standardabweichung von Renditen) über einen Zeitraum. Je höher die <span class="font-bold" >Volatilität</span>, desto risikoreicher die Anlage, da bei höherer <span class="font-bold">Volatilität</span> wahrscheinlicher größere Schwankungen auftreten.',
      },
      beta: {
        title: "Beta",
        para1:
          '<span class="font-bold" >Beta</span> misst die Abhängigkeit von Renditen. Es wird normalerweise verwendet, um die Abhängigkeit eines Wertpapiers oder einer Anlagestrategie von der Rendite des Marktes zu messen. Ein höheres <span class="font-bold" >Beta</span> gibt an, dass sich das Wertpapier aggressiver als der Markt bewegt, während ein niedriges Beta bedeutet, dass das Wertpapier nur teilweise die Markterträge einfängt. Die Grafik unten zeigt Trendlinien einer Anlage mit hohem Beta-Wert und einer mit niedrigem Beta-Wert und wie sie im Vergleich zum Markt abschneiden, wobei die Steigung der Trendlinie den Beta-Wert darstellt. Die Anlage mit niedrigem <span class="font-bold" >Beta</span> zeigt ein <span class="font-bold" >Beta</span> < 1, was bedeutet, dass sie im Allgemeinen weniger als der Markt, aber in die gleiche Richtung bewegt. Die Anlage mit hohem <span class="font-bold" >Beta</span> zeigt ein Beta > 1 und generiert daher extreme Erträge im Vergleich zum Markt. Somit sind die Erträge in der Grafik breiter gestreut als bei der Anlage mit niedrigem <span class="font-bold" >Beta</span>.',
        para2:
          'Obwohl selten, gibt es negative <span class="font-bold">Beta</span>-Werte, die eine inverse Beziehung zum Markt anzeigen würden.',
        para3:
          "Bei der Analyse einer Anlagestrategie suchen Investoren nach gut performenden Strategien mit einem niedrigen Beta. Diese Konstellation zeigt an, dass die Performance des Managers weniger von den Marktrenditen abhängt, sondern von der Fähigkeit des Managers.",
      },
      calmarRatio: {
        title: "Calmar Ratio",
        para1:
          "Ein Verhältnis, das verwendet wird, um die Rendite im Verhältnis zum Drawdown-Risiko (Abwärtsrisiko) eines Anlageprodukts zu bestimmen.",
        para2:
          "Unter sonst gleichen Bedingungen gilt: Je höher die Quote, desto besser die Investition.",
      },
      correlation: {
        title: "Korrelation",
        para1:
          "Der Pearson-Korrelationskoeffizient (PCC) ist ein statistisches Mass, das die Stärke und Richtung einer linearen Beziehung zwischen zwei Datensätzen bewertet. Er wird berechnet als das Verhältnis der Kovarianz der beiden Variablen zum Produkt ihrer Standardabweichungen. Durch diese Normalisierung erhält die PCC einen Wert zwischen -1 und 1, wobei -1 für eine perfekte negative lineare Korrelation, 1 für eine perfekte positive lineare Korrelation und 0 für keine lineare Korrelation steht. Es ist wichtig zu beachten, dass die PCC nur lineare Beziehungen erkennt, nicht aber andere Arten von Korrelationen",
        para2:
          "Bei gepaarten Daten {(X,Y)} von n Paaren wird die PCC mit der folgenden Formel berechnet:",
      },
      cumulativeReturn: {
        title: "Kumulative Rendite",
        para1:
          'Als Prozentsatz dargestellt, ist die <span class="font-bold">kumulative Rendite</span> einer Investition der Gesamtbetrag, den die Investition im Laufe der Zeit gewonnen oder verloren hat.',
        para2:
          'Die <span class="font-bold">kumulative Rendite</span> kann leicht aus einem Diagramm abgeleitet werden, das das Wachstum einer Investition zeigt, wie oben gezeigt. Wenn wir nur den ersten und den letzten Wert des Diagramms verwenden, können wir die kumulierte Gesamtrendite für einen bestimmten Zeitraum ermitteln.',
      },
      informationRatio: {
        title: "Information Ratio",
        para1:
          'Investitionsstrategien werden oft an einem bestimmten Benchmark gemessen, der meist aus einem oder einer Mischung aus vielen Indizes besteht. Wenn ein Investmentmanager den Benchmark schlägt, ist es für den Anleger interessant zu wissen, zu welchem Preis das Ziel erreicht wurde. Das <span class="font-bold" >Information Ratio</span> ist ein Maß für den Vergleich zwischen dem Mehrertrag gegenüber dem Benchmark und dem Tracking Error, der ein Maß für die Konsistenz zwischen der Strategie und dem Benchmark ist.',
        para2:
          'Das <span class="font-bold" >Information Ratio</span> wird auf die gleiche Weise berechnet wie das Sharpe Ratio unter der Annahme, dass der Benchmark einer risikofreien Rendite mit einer Volatilität von 0% entspricht.',
        para3:
          "Wenn alle anderen Faktoren gleich sind, gilt: Je höher das Verhältnis, desto robuster ist die Anlage. Ein hohes Verhältnis bedeutet, dass die täglichen Schwankungen der Excess Returns gegenüber dem Referenzindex im Vergleich zum durchschnittlichen Excess Return gegenüber dem Referenzindex gering sind.",
      },
      kurtosis: {
        title: "Kurtosis",
        para: "Die Kurtosis gibt an, wie spitz eine Verteilung ist. Für eine Normalverteilung ist die Kurtosis 0. Je höher die Kurtosis einer Verteilung ist, desto stärker ist sie um ihren Mittelwert konzentriert.",
      },
      m2RiskAdjustedPerf: {
        title: "M2-Messung",
        para1:
          "Die risikobereinigte M2-Performance (M-Quadrat), auch bekannt als M2-Messung, bewertet die risikobereinigten Erträge eines Anlageportfolios. Es bewertet die Renditen des Portfolios unter Berücksichtigung des Risikos im Vergleich zu einer Benchmark, z. B. dem Markt. Im Wesentlichen stellt sie die Differenz der bereinigten Überschussrendite eines Portfolios (P) gegenüber dem Markt dar, wobei das Portfolio an die Volatilität des Marktes angepasst wird. Sie ist aus der Sharpe-Ratio hervorgegangen und bietet einen wesentlichen Vorteil, da sie die Ergebnisse in prozentualen Renditen ausdrückt, im Gegensatz zur Sharpe-Ratio, die eine nicht-negative Zahl ist und für viele Anleger weniger praktisch ist. Diese Eigenschaft des Modigliani-Maßes macht es wesentlich einfacher, es zu verstehen und anzuwenden.",
        para2:
          "Wenn die Rendite der Anlage höher ist als der risikofreie Zinssatz, ist die Anlage umso besser, je höher das M2-Maß ist, wenn alles andere gleich bleibt. In diesem Fall steigt das M2-Maß, wenn die Rendite der Strategie steigt oder wenn die Volatilität der Strategie im Vergleich zu ihrer Benchmark sinkt.",
      },
      maximumDrawdown: {
        title: "Maximaler Drawdown",
        para1:
          'Ein <span class="font-bold">maximaler Drawdown</span> ist der maximale beobachtete Verlust von einem Hoch zum Tief eines Portfolios, bevor ein neues Hoch erreicht wird. Der <span class="font-bold" >maximale Drawdown</span> ist ein Indikator für das Abwärtsrisiko über einen bestimmten Zeitraum hinweg.',
        para2:
          '<span class="font-bold">Maximaler Drawdown</span> wird verwendet, um die relative Risikohaltigkeit einer Strategie im Vergleich zu einer anderen zu beurteilen, da er sich auf die Kapitalerhaltung konzentriert, was für die meisten Anleger ein Schlüsselanliegen ist. Ein niedriger maximaler Drawdown wird bevorzugt, da dies darauf hinweist, dass Verluste aus Investitionen gering waren. Wenn eine Investition niemals einen Cent verloren hat, wäre der <span class="font-bold" >maximale Drawdown</span> null. Der schlechtmöglichste <span class="font-bold">maximale Drawdown</span> wäre 100%, was bedeutet, dass die Investition vollständig wertlos ist.',
        para3:
          "Je niedriger der absolute Wert des maximalen Drawdowns ist (d. h. je näher der maximale Drawdown bei 0 % liegt), desto zuverlässiger ist die Anlage, wenn alles andere gleich bleibt.",
        keyPoints: "Schlüsselinformationen",
        keyPoint1:
          "Der maximale Drawdown ist ein Maß für den größten Preiseinbruch eines Vermögenswerts von einem Hoch zum Tief.",
        keyPoint2:
          "Der maximale Drawdown gilt als Indikator für das Abwärtsrisiko, wobei große maximale Drawdowns darauf hindeuten, dass Abwärtsbewegungen volatil sein könnten.",
        keyPoint3:
          "Obwohl der maximale Drawdown den größten Verlust misst, berücksichtigt er weder die Häufigkeit von Verlusten noch die Größe von Gewinnen.",
      },
      metaInfo: { title: "Plattform - Wissensdatenbank" },
      omegaRatio: {
        title: "Omega Ratio",
        para1:
          "Das Omega Ratio ist ein Maß für die Risiko-Ertrags-Performance. Sie berechnet das wahrscheinlichkeitsgewichtete Verhältnis von Gewinnen und Verlusten.",
        para2:
          "Unter sonst gleichen Bedingungen gilt: Je höher die Quote, desto besser die Investition.",
      },
      returnVolatilityRatio: {
        title: "Rendite-Volatilitäts-Verhältnis",
        para1:
          'Das <span class="font-bold">Rendite-Volatilitäts-Verhältnis</span> stellt die durchschnittliche Rendite pro Einheit der Volatilität (Standardabweichung) dar, was als Einheit des Risikos interpretiert werden kann. Es ist daher informativ, wie gut ein Investor für das eingegangene Risiko kompensiert wird (je höher das Return-to-Volatility-Verhältnis, desto höher die Risikokompensation).',
        para2:
          'In der Regel wird ein <span class="font-bold">Rendite-Volatilitäts-Verhältnis</span> von mehr als 1,0 über einen längeren Zeitraum als gut betrachtet, da dies bedeutet, dass die Belohnungen höher sind als das Risiko. Wenn beispielsweise die annualisierte Volatilität niedriger ist als die annualisierte Rendite, wird das <span class="font-bold">Rendite-Volatilitäts-Verhältnis</span> größer als 1,0 sein.',
        para3:
          'Daher ermöglicht das <span class="font-bold">Rendite-Volatilitäts-Verhältnis</span> Investoren, sehr unterschiedliche Investitionen anhand desselben Kriteriums zu vergleichen. Das <span class="font-bold">Rendite-Volatilitäts-Verhältnis</span> ermöglicht Vergleiche von Äpfeln mit Birnen.',
        keyPoints: "Hauptpunkte",
        keyPoint1:
          'Ein hohes <span class="font-bold">Rendite-Volatilitäts-Verhältnis</span> zeigt an, wie gut ein Investor für das eingegangene Risiko kompensiert wird.',
        keyPoint2:
          'Das <span class="font-bold">Rendite-Volatilitäts-Verhältnis</span> passt die vergangene Leistung eines Portfolios an das Risiko an, das der Investor eingegangen ist, und ermöglicht so einen Vergleich zwischen sehr unterschiedlichen Investitionen.',
      },
      sharpeRatio: {
        title: "Sharpe Ratio",
        para1:
          'Das <span class="font-bold">Sharpe Ratio</span> ist ein Maß für die risikoadjustierte Rendite. Es vergleicht die Überrendite über einer risikofreien Anlage mit dem Risiko, das aufgewendet wurde, um diese Rendite zu erzielen, wobei das Risiko für den Investor als Volatilität definiert ist. Das Verhältnis hilft dabei, die Rendite zu verstehen, die durch die Einheit des Risikos erreicht wurde, und kann verwendet werden, um mehrere Investitionsmöglichkeiten zu vergleichen.',
        para2:
          "Da keine Anlage völlig risikofrei ist, kann jedes Wertpapier mit geringen Risiken als Ersatz für risikofreie Renditen verwendet werden. In der Regel eignen sich Staatsanleihen aus stabilen Ländern gut für diesen Zweck. Zur Berechnung der risikofreien Rendite verwenden wir die kurzfristigen LIBOR-Sätze.",
        para3:
          "Wenn diese Kennzahl definiert ist (d.h. wenn sie positiv ist), ist die Anlage umso robuster, je höher sie ist, wenn alle anderen Faktoren gleich sind. Eine hohe Kennzahl bedeutet, dass die täglichen Schwankungen der Überschussrendite im Vergleich zur durchschnittlichen Überschussrendite gering sind.",
      },
      skewness: {
        title: "Schiefe",
        para1:
          'Die Häufigkeit des Auftretens großer Renditen in eine bestimmte Richtung wird durch die <span class="font-bold">Schiefe</span> gemessen. Wenn die Häufigkeit des Auftretens großer negativer Renditen im Vergleich zu großen positiven Renditen höher ist, dann weist die Verteilung eine negative <span class="font-bold">Schiefe</span> auf. Wenn die Häufigkeit großer positiver Renditen die von großen negativen Renditen übersteigt, dann zeigt die Verteilung eine positive <span class="font-bold">Schiefe</span>.',
        para2:
          'Investoren sollten sich auf die <span class="font-bold">Schiefe</span> konzentrieren, da sie die Extremwerte der Renditen berücksichtigt und sich nicht nur auf den Durchschnitt konzentriert. Insbesondere kurz- und mittelfristige Investoren müssen auf Extremwerte achten, da sie weniger wahrscheinlich eine Strategie lange genug halten, um sicher zu sein, dass sich der Durchschnitt selbst ausgleicht.',
      },
      sortinoRatio: {
        title: "Sortino Ratio",
        para1:
          "Die Sortino Ratio ähnelt der Sharpe Ratio, mit der Ausnahme, dass sie das Abwärtsrisiko (Downside-Volatilität) im Nenner verwendet. Da Schwankungen nach oben nicht unbedingt etwas Schlechtes sind, ist die Sortino Ratio manchmal besser als die Sharpe Ratio.",
        para2:
          "Unter sonst gleichen Bedingungen gilt: Je höher die Quote, desto besser die Investition.",
      },
      trackingError: {
        title: "Tracking Error",
        para1:
          'Der <span class="font-bold">Tracking Error</span> ist ein Maß für das Risiko eines Anlageportfolios aufgrund der Entscheidungen des Managers, von seinem Benchmark abzuweichen. Je höher es ist, desto aktiver ist der Manager in der Wahl, von seinem Benchmark abzuweichen.',
        para2:
          "Mathematisch kann es relevant sein, ihn zu schätzen, indem die Standardabweichung der Differenz zwischen der Rendite des Portfolios und des Benchmarks berechnet wird. Die von uns auf dieser Plattform präsentierte Version ist annualisiert.",
        para3:
          'Es ist wichtig zu beachten, dass er nicht die Relevanz der Entscheidungen des Managers repräsentiert, sondern die Auswirkungen, die sie auf das endgültige Portfolio haben. Somit können zwei Portfolios mit hervorragender oder schlechter Leistung im Vergleich zu ihrem Benchmark beide hohe <span class="font-bold">Tracking Error</span> aufweisen.',
        para4:
          "Ein niedriger Tracking Error bedeutet, dass eine Anlage nicht stark von ihrer Benchmark abweicht. Ein hoher Tracking Error bedeutet, dass die Anlage stark von ihrer Benchmark abweicht.",
      },
      treynorRatio: {
        title: "Treynor-Ratio",
        para: 'Ähnlich wie die Sharpe Ratio ist die Treynor Ratio ein Maß für die Effizienz, das das Verhältnis zwischen der annualisierten risikobereinigten Rendite und dem Risiko verwendet. Im Gegensatz zur Sharpe Ratio wird bei der Treynor Ratio das "Marktrisiko" (Beta) anstelle des Gesamtrisikos (Standardabweichung) verwendet. Eine gute Performance-Effizienz wird durch eine hohe Ratio gemessen.',
      },
      valueAtRisk: {
        title: "Value-at-Risk",
        para1:
          "VaR ist eine Statistik, die das Ausmaß möglicher Verluste innerhalb eines Portfolios über einen bestimmten Zeitraum und mit einer bestimmten Wahrscheinlichkeit quantifiziert. VaR 1% über 1 Monat = x% Verlust in 1 Monat mit 1% Wahrscheinlichkeit. Je niedriger, desto besser!",
        para2:
          "In der Praxis ist es das Quantil der Renditen der Strategie, das dem p-Wert (1% oder 5%) entspricht.",
      },
    },
  },
  home: {
    metaInfo: {
      title: (ctx) => `${ctx.named("orgName")} - Plattform }`,
    },
    modifyInvestorProfile: "Investorenprofil bearbeiten",
    noProductsAvailable: "Keine Produkte verfügbar für Ihre Rolle und Ihr Investorprofil.",
    search: "Suche",
    showMoreProducts: "Mehr Produkte anzeigen",
  },
  products: {
    analysis: {
      analysisPeriod: "Analysezeitraum",
      backtest: "Backtest",
      backtestOrLive: "Backtest oder Live",
      benchmarks: "Vergleichsindizes",
      live: "Live",
      liveProduct: (ctx) => `${ctx.named("productName")} Live`,
      shareClass: "Anteilsklasse",
      graphs: {
        considerApproximation: "Diese Werte sollten als Näherungswerte betrachtet werden.",
        weHave: "Wir haben",
        weWant: "Wir wollen",
        titles: {
          assetClassesWeights: "Gewichtung der Anlageklassen",
          beta: "Beta",
          constituents: "Bestandteile",
          contango: "Contango",
          diffMonthlyReturns: "Diff. monatliche Renditen",
          drawdowns: "Drawdowns",
          exposureIndices: "Indexgewichtung",
          exposureOverTime: "Gewichte im Laufe der Zeit",
          indicators: "Indikatoren",
          monthlyReturns: "Monatliche Renditen",
          performance: "Performance",
          ratioVixFutures: "Verhältnis VIX-Futures",
          recentDataOverview: "Übersicht über aktuelle Daten",
          relativeDrawdowns: "Relative Drawdowns",
          relativePerformance: "Relative Performance",
          riskAdjustedPerformance: "Risikoadjustierte Performance",
          rollingVRatioLevel: "Rolling VRatio Level",
          statistics: "Statistiken",
          returnProfile: "Renditeprofil",
          top10ConstituentsWeight: "Top 10 Gewichte",
          top5ConstituentsWeight: "Top 5 Gewichte",
          vegaIndicatorConditions: "Vega-Indikatorbedingungen",
          vix: "VIX",
          vixPremium: "VIX Premium",
          volatility: "Volatilität",
          weightedPerformance: "Gewichtete Performance",
          yearlyReturns: "Jährliche Renditen",
        },
        tooltips: {
          beta: "Dieses Diagramm zeigt das Beta unseres Produkts im Vergleich zu unserem Benchmark. Zur Berechnung verwenden wir Daten aus dem letzten verfügbaren Jahr, daher fehlt die Kennzahl im ersten Jahr des Produkts.",
          relativePerformance:
            "Dieses Diagramm zeigt einen Vergleich der Performance unseres Produkts und der Performance unseres Benchmarks. Dazu werden unsere kumulierten Renditen durch die kumulierten Renditen unseres Benchmarks geteilt. Dies bedeutet, dass bei 100% beide Leistungen ähnlich sind. Ansonsten gilt: Je höher die Zahl, desto besser ist die Leistung unseres Produkts im Vergleich zum Benchmark.",
          riskAdjustedPerformance:
            "Dieses Diagramm zeigt die risikoadjustierte Performance. Zur Berechnung verwenden wir Daten aus dem letzten verfügbaren Jahr, daher fehlt die Kennzahl im ersten Jahr des Produkts. Die risikoadjustierte Performance wird definiert als das Verhältnis zwischen annualisierter Rendite und annualisierter Volatilität.",
          volatility:
            "Dieses Diagramm zeigt die annualisierte Volatilität (d.h. Standardabweichung). Zur Berechnung verwenden wir Daten aus dem letzten verfügbaren Jahr, weshalb die Kennzahl im ersten Jahr des Produkts fehlt.",
        },
      },
      metaInfo: { title: (ctx) => `${ctx.named("productName")} - Analyse` },
      tab: {
        historical: "Historisch",
        historicalText:
          'Für bereits gestartete Produkte unterscheiden wir im Bereich "Analyse" zwischen "Live-Analyse" und "Historischer Analyse". Die "Historische Analyse" soll die Performance des Produkts vor dessen Einführung veranschaulichen. Die Performance basiert auf einer realistischen Simulation der Strategie. Es waren jedoch keine Assets under Management während des entsprechenden Zeitraums beteiligt.',
        internal: "Intern",
        live: "Live",
        liveText:
          'Für bereits gestartete Produkte unterscheiden wir im Bereich "Analyse" zwischen "Live-Analyse" und "Historischer Analyse". Die "Live-Analyse" veranschaulicht die Performance seit Einführung des Produkts.',
      },
    },
    documentDownload: {
      failure:
        "Die Datei konnte nicht heruntergeladen werden. Bitte kontaktieren Sie uns, wenn dies unerwartet ist.",
      needAuth:
        "Sie müssen angemeldet sein, um dieses Dokument herunterzuladen. Bitte melden Sie sich an.",
      preparing: "Die Datei wird für den Download vorbereitet ...",
      seeProductOverview: "Produktübersicht anzeigen",
      success: "Die Datei wurde erfolgreich heruntergeladen.",
      metaInfo: {
        title: (ctx) => `${ctx.named("productName")} - Dokumente herunterladen`,
      },
    },
    documents: {
      addFile: "Datei hinzufügen",
      document: "Dokument",
      nameValidationError:
        "Der Name wird bereits von einem anderen Ihrer Dokumente verwendet. Bitte wählen Sie einen anderen Namen.",
      noDocument: "Es wurde kein Dokument für dieses Produkt bereitgestellt.",
      noArchivedDocument: "Kein archiviertes Dokument für dieses Produkt.",
      noteDragAndDrop:
        "Hinweis: Ziehen Sie Zeilen per Drag & Drop, um die Dokumente neu anzuordnen.",
      shareClass: "Anteilsklasse",
      archiveIconTooltip: "Archivieren",
      unarchiveIconTooltip: "Entarchivieren",
      archivedDocumentsCardTitle: "Archivierte Dokumente",
      dialog: {
        fileDeletion: {
          title: "Dateilöschung",
          text: "Sie werden die Datei vollständig löschen und keinen Zugriff mehr darauf haben. Sind Sie sicher?",
        },
        fileArchiving: {
          title: "Dateiarchivierung",
          text: 'Sie werden diese Datei archivieren, d.h. sie wird in Ihre "archivierten Dokumente" (gleich unterhalb der Dokumente) verschoben und für normale Benutzer nicht sichtbar sein. Beachten Sie, dass es möglich sein wird, sie zu entarchivieren. Möchten Sie sie archivieren?',
        },
        fileUnarchiving: {
          title: "Datei-Entarchivierung",
          text: "Sie werden diese Datei entarchivieren, d.h. sie wird zurück zu Ihren normalen Dokumenten verschoben und wieder für normale Benutzer sichtbar sein. Möchten Sie sie entarchivieren?",
        },
        needLogin: {
          text: "Um dieses Dokument herunterladen zu können, müssen Sie authentifiziert sein.",
          title: "Sie müssen authentifiziert sein",
        },
      },
      metaInfo: { title: (ctx) => `${ctx.named("productName")} - Dokumente` },
      notify: {
        savingDocumentsError:
          "Beim Aktualisieren der Dokumente ist ein Fehler aufgetreten. Versuchen Sie es später erneut oder kontaktieren Sie uns.",
        savingDocumentsSuccess: "Dokumente erfolgreich aktualisiert.",
        fileDownloadError: "Fehler beim Herunterladen des Dokuments, bitte kontaktieren Sie uns.",
      },
      prompt: {
        fileDisplayName: "Angezeigter Name des Dokuments",
        loginRequired: "Anmeldung erforderlich?",
        uploadFile: {
          acceptText: "Datei hochladen",
          file: "Datei",
          fileLanguage: "Sprache des Dokuments",
          shouldConsiderLastFactsheet:
            "Soll dieses Dokument als das letzte Factsheet für das Produkt betrachtet werden?",
          title: "Datei hochladen",
        },
        editFile: {
          title: "Dokument bearbeiten",
        },
      },
    },
    embedded: {
      perfGraph: {
        metaInfo: {
          title: (ctx) => `${ctx.named("productName")} - Eingebettetes Performance-Diagramm`,
        },
      },
      perfTable: {
        currency: "Währung",
        inceptionDate: "Lancierungsdatum",
        name: "Name",
        lastPriceAsOf: "Letzter Preis zum",
        mtd: "MTD",
        si: "SI",
        y1: "-1J",
        y3: "-3J",
        ytd: "YTD",
        metaInfo: {
          title: (ctx) => `${ctx.named("productName")} - Eingebettete Performance-Tabelle`,
        },
      },
      statsTable: {
        feesIncluded: "Gebühren sind enthalten.",
        metaInfo: {
          title: (ctx) => `${ctx.named("productName")} - Eingebettete Statistik-Tabelle`,
        },
      },
    },
    events: {
      covidCrisis: "COVID-19-Krise",
      crisis2022: "Krise 2022",
      december2018Crisis: "Dezember 2018 Krise",
      euroCrisis: "Eurokrise",
      financialCrisis: "Finanzkrise",
      alvolaLive: "Alvola Live",
      convexusLive: "Convexus Live",
      lstsLive: "LSTS Live",
      protechLive: "ProTech Live",
      sinceInception: "Seit Lancierung",
      ynnovationLive: "Ynnovation Live",
      calmgard: {
        calmgardCovid19Crisis: "Covid 19-Krise",
        calmgardEuroCrisisOne: "Euro-Krise I",
        calmgardEuroCrisisTwo: "Euro-Krise II",
        calmgardGriechenlandUndChinaKrise: "Griechenland- und China-Krise",
        calmgardInterestRateSpeculationOne: "Zinsspekulation I",
        calmgardInterestRateSpeculationTwo: "Zinsspekulation II",
        calmgardPostFinancialCrisis: "Nach der Finanzkrise",
        calmgardUkraineWar: "Ukraine-Krieg",
      },
      falgom: {
        falgomCoronavirusPandemic: "Coronavirus-Pandemie",
        falgomFedQuantitativeTapering: "Quantitative Reduzierung der FED",
        falgomGlobalFinancialCrisis: "Globale Finanzkrise",
        falgomGrowthSlowdownChina: "Wachstumsverlangsamung China",
        falgomRecessionEurope: "Rezession Europa",
        falgomVolmageddon: "Volmageddon",
      },
    },
    factsheet: {
      firstDateToInclude: "Erstes Datum (JJJJ-MM-TT) einschließen",
      lastDateToInclude: "Letztes Datum (JJJJ-MM-TT) einschließen",
      nameValidationError:
        "Der Name wird bereits von einem anderen Ihrer Dokumente verwendet. Bitte wählen Sie einen anderen Namen.",
      shareClass: "Anteilsklasse",
      year: "Jahr",
      cardTitle: {
        assetClasses: "Assetklassen",
        contacts: "Kontakte",
        currencyExposure: "Währungsexposition",
        description: "Beschreibung",
        disclaimer: "Haftungsausschluss",
        drawdowns: "Drawdowns",
        historicalPrices: "Historische Preise",
        instrumentTypes: "Instrumententypen",
        monthlyComment: "Monatlicher Kommentar",
        monthlyPerformance: "Monatliche Performance",
        monthlyReturns: "Monatliche Renditen",
        priceChart: "Preisdiagramm",
        productDetails: "Produktdetails",
        regions: "Regionen",
        relativePerformance: "Relative Performance",
        riskProfile: "Risikoprofil",
        sectors: "Sektoren",
        shareClasses: "Anteilsklassen",
        topHoldings: "Einzelpositionen",
        volatility: "Volatilität",
        yearlyPerformance: "Jährliche Performance",
      },
      dragAndDrop: {
        addGenericComponent: "Komponente hinzufügen",
        availableGenericComponents: "Generische Komponenten",
        availablePredefinedComponents: "Vordefinierte Komponenten",
        editFactsheet: "Factsheet bearbeiten",
        componentTooBig:
          "Die Komponente ist zu groß, um auf einer einzelnen Seite enthalten zu sein.",
        saveSuccessText: "Die Factsheet-Konfiguration wurde erfolgreich aktualisiert.",
        stopEdit: "Bearbeitung beenden",
        footer: {
          title: "Fußzeile",
          page: "Seite",
        },
        forcedElementDeletionWarning: {
          componentsWereRemoved:
            "Einige Komponenten wurden aus Ihrem Layout entfernt, da ihre Größe den anzeigbaren Bereich einer einzelnen Seite überschreitet oder ihre Spalte zu groß ist. Die IDs der entfernten Komponenten sind:",
          resolveSolution:
            "Um dieses Problem zu lösen, verringern Sie die Höhe der betroffenen Komponenten, platzieren Sie die Komponente in einer neuen Einzelreihe oder kontaktieren Sie uns.",
          title: "Erzwungenes Entfernen von Komponenten aus dem Layout",
        },
        header: {
          title: "Kopfzeile",
        },
        style: {
          title: "Stil",
        },
      },
      generateFactsheet: {
        downloadFactsheet: "Factsheet herunterladen",
        generatingFactsheet: "Factsheet wird generiert...",
        saveFactsheet: "Factsheet in Dokumenten speichern",
        notify: {
          generatingError: "Fehler beim Generieren eines Factsheets, bitte kontaktieren Sie uns.",
          savedInDocumentsSection: "Ihr Factsheet wurde im Dokumentenbereich gespeichert.",
        },
        prompt: {
          acceptText: "Factsheet speichern",
          considerLastFactsheet:
            "Soll diese Datei als das letzte Factsheet für das Produkt betrachtet werden?",
          fileDisplayName: "Anzeigename der Datei",
          loginRequired: "Anmeldung erforderlich?",
          title: "Factsheet generieren und hochladen",
        },
      },
      metaInfo: { title: (ctx) => `${ctx.named("productName")} - Factsheet` },
      shareClassesTable: {
        bloomberg: "Bloomberg",
        class: "Klasse",
        currency: "Währung",
        inception: "Lancierungsdatum",
        isin: "ISIN",
        mgmtFee: "Mgmt. fee",
        mtdPerf: "MTD Perf.",
        nav: "NAV",
        navDate: "NAV Datum",
        perfFee: "Perf. fee",
        siPerf: "Perf. Lanc.",
        ytdPerf: "YTD Perf.",
      },
      statistics: {
        title: "Statistiken",
      },
      statisticsWithPeriods: {
        title: "Performance",
        periods: {
          1: "1M",
          3: "3M",
          6: "6M",
          ytd: "YTD",
          12: "1J",
          36: "3J",
          60: "5J",
          max: "MAX",
        },
      },
    },
    months: {
      short: {
        jan: "Jan",
        feb: "Feb",
        mar: "Mär",
        apr: "Apr",
        may: "Mai",
        jun: "Jun",
        jul: "Jul",
        aug: "Aug",
        sep: "Sep",
        oct: "Okt",
        nov: "Nov",
        dec: "Dez",
      },
    },
    overview: {
      assetClasses: "Assetklassen",
      currencyExposure: "Währungsexposition",
      keyCharacteristics: "Hauptmerkmale",
      historicalPrices: "Historische Preise",
      instrumentTypes: "Instrumententypen",
      itd: "ITD-Rendite",
      itdDescription: (ctx) =>
        `ITD (Inception-to-Date) ist ein Zeitraum, der vom Produktbeginn (${ctx.named(
          "date"
        )}) bis zum heutigen Tag dauert.`,
      lastChange: "Letzte Änderung",
      lastDayChange: "Änderung am letzten Tag",
      lastWeekChange: "Änderungen in der letzten Woche",
      lastMonthChange: "Änderung im letzten Monat",
      lastQuarterChange: "Änderung im letzten Quartal",
      lastYearChange: "Veränderung im letzten Jahr",
      monthlyReturns: "Monatliche Renditen",
      lastPrice: "Preis",
      performance: "Performance",
      productDetails: "Produktdetails",
      productTimeline: "Produktzeitachse",
      regions: "Regionen",
      sectors: "Sektoren",
      shareClass: "Anteilsklasse",
      topHoldings: "Einzelpositionen",
      yearlyReturns: "Jährliche Renditen",
      ytd: "YTD-Rendite",
      ytdDescription:
        "YTD (Year-to-Date) ist ein Zeitraum, der vom Beginn des aktuellen Kalenderjahres bis zum heutigen Tag dauert.",
      dragAndDrop: {
        addGenericComponent: "Komponente hinzufügen",
        availableGenericComponents: "Generische Komponenten",
        availablePredefinedComponents: "Vordefinierte Komponenten",
        editOverview: "Übersicht bearbeiten",
        saveSuccessText: "Die Übersichtskonfiguration wurde erfolgreich aktualisiert.",
        stopEdit: "Bearbeitung stoppen",
      },
      follow: {
        buttonText: "Folgen",
        buttonTextFollowing: "Folge ich",
        notify: {
          error:
            "Es gab einen Fehler. Versuchen Sie es erneut oder kontaktieren Sie uns, wenn das Problem weiterhin besteht.",
          followSuccess: (ctx) => `Sie folgen jetzt ${ctx.named("productName")}.`,
          unfollowSuccess: (ctx) => `Sie folgen ${ctx.named("productName")} nicht mehr.`,
        },
        promptNotFollowing: {
          acceptText: "Folgen",
          text: "Durch das Folgen dieses Produkts stimmen Sie zu, von Zeit zu Zeit Informationen zum Produkt zu erhalten. Sie können jederzeit aufhören, solche Informationen zu erhalten, indem Sie nicht mehr folgen.",
          title: "Produkt folgen",
        },
        promptFollowing: {
          acceptText: "Entfolgen",
          text: "Sie folgen derzeit diesem Produkt. Möchten Sie ihm nicht mehr folgen und keine Informationen zum Produkt mehr erhalten?",
          title: "Nicht mehr folgen",
        },
        promptNotLoggedIn: {
          cancelText: "Ein Konto erstellen",
          text: "Wenn Sie diesem Produkt folgen möchten, müssen Sie in Ihr Konto eingeloggt sein. Wenn Sie kein Konto haben, können Sie kostenlos eines erstellen.",
          title: "Produkt folgen",
        },
      },
      metaInfo: { title: (ctx) => `${ctx.named("productName")} - Übersicht` },
      statsOptions: {
        1: "1 Monat",
        3: "3 Monate",
        6: "6 Monate",
        ytd: "Bisheriges Jahr",
        12: "1 Jahr",
        36: "3 Jahre",
        60: "5 Jahre",
        max: "Seit Lancierung",
      },
    },
    useCase: {
      allocationAlvola: "Allokation zu Alvola",
      biggestDrawdowns: (ctx) => `Größte Drawdowns von ${ctx.named("benchmark")}`,
      description:
        "Unser Use Case zeigt, wie die Hinzufügung der Alquant Alvola Strategie zu einem bestehenden Aktienportfolio die Rendite langfristig steigern und gleichzeitig den maximalen Drawdown reduzieren kann. Wählen Sie Ihr gewünschtes Aktienportfolio und die Allokation zur Alquant Alvola Strategie und entdecken Sie, wie sich die Kombination in der Vergangenheit (vor der Einführung unseres Produkts am 02. Jun 2021) entwickelt hätte. Zusätzlich finden Sie auch die Performance während der 10 größten Drawdown-Perioden Ihres ausgewählten Aktienportfolios.",
      drawdowns: "Drawdowns",
      equityPortfolio: "Aktienportfolio",
      historicalResults: "Historische Ergebnisse",
      portfolioAllocation: "Portfolio Allokation",
      protectPortfolio: "Schützen Sie Ihr Portfolio mit Alvola",
      metaInfo: { title: (ctx) => `${ctx.named("productName")} - Use Case` },
    },
  },
  settings: {
    metaInfo: { title: (ctx) => `Einstellungen - ${ctx.named("name")}` },
    changePassword: {
      buttonReset: "E-Mail zum Zurücksetzen des Passworts senden",
      title: "Passwort ändern",
      notify: {
        success: {
          title: "E-Mail zum Zurücksetzen gesendet",
          text: (ctx) =>
            `Eine E-Mail zum Zurücksetzen wurde an den Posteingang von ${ctx.named(
              "email"
            )} gesendet. Wenn sie nicht in Kürze erscheint, prüfen Sie den Spam-Posteingang.`,
        },
        failure:
          "Wir konnten Ihr Passwort nicht ändern. Bitte kontaktieren Sie uns über das Kontaktformular, um diesen Fehler zu beheben!",
      },
    },
    deleteAccount: {
      deleteYourAccount: "Ihr Konto löschen",
      title: "Konto löschen",
      notify: {
        success: "Ihr Konto wurde erfolgreich gelöscht.",
      },
      dialog: {
        permanentlyDeleteData: {
          text: "Das Löschen Ihres Kontos löscht alle Ihre Daten dauerhaft.",
          title: "Bestätigen",
        },
        recentLoginNeeded: "Letzter Login erforderlich",
      },
    },
    editProfile: {
      company: "Firma",
      email: "E-Mail",
      firstName: "Vorname",
      investorProfileCountry: "Land",
      investorProfileType: "Investorentyp",
      lastName: "Nachname",
      phone: "Telefon",
      title: "Profil bearbeiten",
      requiredFields:
        'Felder, die mit <span class="text-danger">*</span> gekennzeichnet sind, sind erforderlich.',
      dialog: {
        askValidation: {
          title: "Verifizierung persönlicher Daten",
          text: "Bitte überprüfen Sie Ihre persönlichen Daten und speichern Sie sie, wenn Sie fertig sind.",
        },
      },
      notify: {
        errorText:
          "Wir konnten Ihre Daten nicht aktualisieren. Bitte kontaktieren Sie uns über das Kontaktformular, um diesen Fehler zu beheben!",
        successText: "Das Update war erfolgreich",
      },
    },
    overview: {
      email: "E-Mail",
      name: "Name",
      title: "Kontoübersicht",
    },
  },
};
