import { cloneDeep } from "lodash";

import { Role } from "@/acl/roles";
import { GenericProductCards } from "@/assets/constants/analysisCards";
import { GLOBAL_EVENTS } from "@/assets/constants/eventsDates";
import { UpdateFrequency } from "@/assets/constants/frequencies";
import { STANDARD_PERF_STATS_WITHOUT_BENCHMARK } from "@/assets/constants/perfStats";
import { STANDARD_ZOOM_BUTTONS } from "@/assets/constants/zoomButtons";
import exports from "@/assets/scss/exports.module.scss";

const ORG_COLOR = exports["colors-primary"];
const INTERNAL_ALLOWED_ROLES = [Role.admin, Role.superAdmin];

// ******---------- Global Gate Fixed Income Fund ----------*****
const fifA = {
  productId: "fif-a",
  storeModule: "fifA",
  productName: "Global Gate Fixed Income Fund Class A",
  productShortName: "Global Gate Fixed Income Fund",
  productColor: ORG_COLOR,
  productShareClassRoot: "fif",
  isDefaultShareClass: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: true,
  allowHoldingDataModification: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.weekly,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const fifD = cloneDeep(fifA);
fifD.productId = "fif-d";
fifD.storeModule = "fifD";
fifD.productName = "Global Gate Fixed Income Fund Class D";
fifD.isDefaultShareClass = false;

const fifF = cloneDeep(fifA);
fifF.productId = "fif-f";
fifF.storeModule = "fifF";
fifF.productName = "Global Gate Fixed Income Fund Class F";
fifF.isDefaultShareClass = false;

const fifO = cloneDeep(fifA);
fifO.productId = "fif-o";
fifO.storeModule = "fifO";
fifO.productName = "Global Gate Fixed Income Fund Class O";
fifO.isDefaultShareClass = false;

const fifP = cloneDeep(fifA);
fifP.productId = "fif-p";
fifP.storeModule = "fifP";
fifP.productName = "Global Gate Fixed Income Fund Class P";
fifP.isDefaultShareClass = false;

const fifE = cloneDeep(fifA);
fifE.productId = "fif-e";
fifE.storeModule = "fifE";
fifE.productName = "Global Gate Fixed Income Fund Class E";
fifE.isDefaultShareClass = false;

// ******---------- Global Gate Multi-Asset Balanced Fund ----------*****
const mabB = {
  productId: "mab-b",
  storeModule: "mabB",
  productName: "Global Gate Multi-Asset Balanced Fund Class B",
  productShortName: "Global Gate Multi-Asset Balanced Fund",
  productColor: ORG_COLOR,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: true,
  allowHoldingDataModification: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.weekly,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

// ******---------- Global Gate High Income Opportunities Fund ----------*****
const hioH = {
  productId: "hio-h",
  storeModule: "hioH",
  productName: "Global Gate High Income Opportunities Fund Class H",
  productShortName: "Global Gate High Income Opportunities Fund",
  productColor: ORG_COLOR,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: true,
  allowHoldingDataModification: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.weekly,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

// ******---------- Global Gate Dynamic Allocation Fund ----------*****
const dafA = {
  productId: "daf-a",
  storeModule: "dafA",
  productName: "Global Gate Dynamic Allocation Fund Class A",
  productShortName: "Global Gate Dynamic Allocation Fund",
  productColor: ORG_COLOR,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: true,
  allowHoldingDataModification: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.weekly,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

export const GLOBAL_GATE_PRODUCTS_DETAILS = [fifA, fifD, fifF, fifO, fifP, fifE, mabB, hioH, dafA];

export const GLOBAL_GATE_PRODUCTS = GLOBAL_GATE_PRODUCTS_DETAILS.map(
  (details) => details.storeModule
);
