import { Role } from "@/acl/roles";
import { GLOBAL_GATE_PRODUCTS_DETAILS } from "@/assets/constants/products/global-gate";

export default {
  orgName: "Global Gate AM",
  logoPath: require("@/assets/images/logo/global-gate.svg"),
  navBarLogoStyle: "width: 100%; max-height: 100px;",
  allowedLoginMethods: ["Google"],
  products: GLOBAL_GATE_PRODUCTS_DETAILS,
  possibleRoles: [Role.superAdmin, Role.admin],
  admin: true,
  availableLanguages: ["en"],
  hasUpdatablePriceData: true,
  hasHoldingData: true,
};
